import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Grid } from '@material-ui/core'
import { Search } from '@material-ui/icons'

const CustomInput = (props) => {
    const shrink = props.shrink ? props.shrink.toString() : 'false'
    return (
        <div className={`customInput ${props.className}`}>
            {props.borderType === 'none' ? (
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item>
                        <Search />
                    </Grid>
                    <Grid item>
                        <TextField id={props.id} onChange={props.onChange} label={props.label} />
                    </Grid>
                </Grid>
            ) : (
                <TextField
                    value={props.value}
                    autoFocus={props.autoFocus}
                    name={props.name}
                    onChange={props.onChange || props.onChange}
                    onBlur={props.onBlur || props.onBlur}
                    InputProps={{
                        inputProps: props.inputProps,
                        startAdornment: props.startAdornment,
                        endAdornment: props.endAdornment,
                        shrink: shrink,
                    }}
                    id={props.id}
                    label={props.label}
                    type={props.type}
                    size={props.size}
                    disabled={props.disabled}
                    variant={`${props.variant || 'outlined'}`}
                    placeholder={props.placeholder}
                    error={props.error}
                    helperText={props.helperText}
                    onClick={props.onClick}
                    onFocus={props.onFocus}
                />
            )}
        </div>
    )
}

export default CustomInput
