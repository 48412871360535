import React, { useReducer } from 'react'
import AuthContext from './authContext'
import AuthReducer from './authReducer'
import { apiCall, setAuthToken } from '../../common/api'
import { response } from '../common'
import { USER_LOADED, LOGOUT, RESPONSE_STATUS, SET_COMPANY_NAME, CLEAR_RESPONSE } from './authTypes'

const AuthState = (props) => {
    const initialState = {
        token: localStorage.getItem('token'),
        isAuthenticated: null,
        isAdmin: null,
        loading: true,
        user: {},
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(AuthReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    // Register User
    const register = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'onBoarding', formData, '', 'auth')])
            resp.commonResponse(res.data, 'register')
        } catch (err) {
            resp.commonErrorResponse('register')
        }
    }

    // Check Email User
    const checkValidation = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkValidation', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'checkValidation')
        } catch (err) {
            resp.commonErrorResponse('checkValidation')
        }
    }

    // Login User
    const login = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'login', formData, '', 'auth')])
            resp.commonResponse(res.data, 'login')
            if (res.data.status === 'success') {
                if (res.data.data.responseType === 5 || res.data.data.responseType === 7) {
                    localStorage.setItem('token', res.data.data.responseData.token)
                    loadUser()
                }
            }
        } catch (err) {
            resp.commonErrorResponse('login')
        }
    }

    // Switch User
    const switchUser = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'switchuser', formData, '', 'auth')])
            console.log('resbdvdb', res.data)
            resp.commonResponse(res.data, 'switchuser')
            if (res.data.status === 'success') {
                if (res.data.data.responseType === 5 || res.data.data.responseType === 7) {
                    localStorage.setItem('token', res.data.data.responseData.token)
                    // loadUser()
                }
            }
        } catch (err) {
            resp.commonErrorResponse('switchuser')
        }
    }

    // Load User
    const loadUser = async () => {
        if (localStorage.token) {
            setAuthToken(localStorage.token)
        }
        const [res] = await Promise.all([apiCall('post', 'validateUser', {}, '', 'auth')])
        if (res.data.status === 'success') {
            dispatch({
                type: USER_LOADED,
                payload: {
                    data: res.data.data.responseData,
                },
            })
            retrieveAccount(res.data.data.responseData.account_id)
        } else if (res.data.status === 'error') {
            setAuthToken()
            dispatch({
                type: LOGOUT,
            })
        } else {
            setAuthToken()
            dispatch({
                type: LOGOUT,
            })
        }
    }

    const retrieveAccount = async (account_id) => {
        const [res] = await Promise.all([
            apiCall('post', 'retriveAccountDetails', { account_id }, '', 'auth'),
        ])
        if (
            res.data.status === 'success' &&
            res?.data?.data?.responseData?.business_profile?.name
        ) {
            dispatch({
                type: SET_COMPANY_NAME,
                payload: {
                    data: res.data.data.responseData.business_profile.name,
                },
            })
        }
    }

    const validateSeller = async (token) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'validateSeller', token, '', 'auth')])
            resp.commonResponse(res.data, 'validateSeller')
            if (res.data.status === 'success') {
                if (res.data.data.responseType === 5 || res.data.data.responseType === 7) {
                    localStorage.setItem('token', res.data.data.responseData.token)
                    loadUser()
                }
            }
        } catch (err) {
            resp.commonErrorResponse('validateSeller')
        }
    }

    // Log out
    const logout = () => {
        setAuthToken()
        dispatch({
            type: RESPONSE_STATUS,
            payload: {
                status: 'success',
                message: 'Logged out successfully',
            },
        })
        dispatch({
            type: LOGOUT,
        })
    }

    // Clear Response
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                isAuthenticated: state.isAuthenticated,
                isAdmin: state.isAdmin,
                loading: state.loading,
                user: state.user,
                responseStatus: state.responseStatus,
                register,
                checkValidation,
                login,
                logout,
                loadUser,
                retrieveAccount,
                clearResponse,
                validateSeller,
                switchUser,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState
