import React, { useReducer } from 'react'
import AdminContext from './adminContext'
import AdminReducer from './adminReducer'
import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_ACCOUNTS,
    GET_ALL_SELLERS,
    GET_ALL_PG_ACCOUNTS,
    SET_RESPONSE,
    GET_REVENUE_CHARGES,
    GET_REVENUE_CHARGE_FEES,
    GET_SINGLE_ACCOUNT,
} from './adminTypes'

import { apiCall } from '../../common/api'
import { response } from '../common'

const AdminState = (props) => {
    const initialState = {
        responseStatus: null,
        all_accounts: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_pg_accounts: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_sellers: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_revenue_charges: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_revenue_charge_fees: {
            records: [],
        },
        single_account: [],
    }
    const [state, dispatch] = useReducer(AdminReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAccountsList = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getAccountsList', formData, '', 'admin/accounts'),
            ])
            const from = 'getAccountsList'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_ACCOUNTS,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const activateAccount = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'activateAccount', formData, '', 'admin/accounts'),
            ])
            const from = 'activateAccount'
            if (res.data.status === 'success') {
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getPGAccountsList = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getPGAccountsList', formData, '', 'admin/accounts'),
            ])
            const from = 'getPGAccountsList'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_PG_ACCOUNTS,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSellersList = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSellersList', formData, '', 'admin/accounts'),
            ])
            const from = 'getSellersList'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SELLERS,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getRevenueCharges = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getRevenueCharges', formData, '', 'revenue'),
            ])
            const from = 'getRevenueCharges'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_REVENUE_CHARGES,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getRevenueChargeFees = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getRevenueChargeFees', formData, '', 'revenue'),
            ])
            const from = 'getRevenueChargeFees'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_REVENUE_CHARGE_FEES,
                    payload: {
                        records: res.data.data.responseData ? res.data.data.responseData : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const uploadCSVAndGenerate = async (formData) => {
        const from = 'uploadCSVAndGenerate'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'uploadCSVAndGenerate', formData, '', 'revenue'),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: { status: res.data.status, message: res.data.data.message, from },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const toggleSplitPayment = async (formData) => {
        const from = 'toggleSplitPayment'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'auth')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: { status: res.data.status, message: 'Something went wrong!' },
            })
        }
    }

    const createNewAuctionIOAccount = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'createNewAuctionIOAccount', formData, '', 'admin/accounts'),
            ])

            const from = 'createNewAuctionIOAccount'

            if (res.data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: { status: res.data.status, message: res.data.data.message, from },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const updateAuctionIOAccount = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateAuctionIOAccount', formData, '', 'admin/accounts'),
            ])

            const from = 'updateAuctionIOAccount'

            if (res.data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: { status: res.data.status, message: res.data.data.message, from },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const deleteAuctionIOAccount = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'deleteAuctionIOAccount', formData, '', 'admin/accounts'),
            ])

            const from = 'deleteAuctionIOAccount'

            if (res.data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: { status: res.data.status, message: res.data.data.message, from },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getAccountDetails = async (formData) => {
        const from = 'getAccountDetails'
        try {
            const res = await Promise.all([
                apiCall('post', 'getAccountDetails', formData, '', 'admin/accounts'),
            ])
            if (res[0].data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_ACCOUNT,
                    payload: {
                        records: res[0].data.data.responseData.records
                            ? res[0].data.data.responseData.records
                            : {},
                    },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const updateAccount = async (formData) => {
        const from = 'updateAccount'
        try {
            console.log(formData)
            const res = await Promise.all([
                apiCall('post', 'updateAccount', formData, '', 'admin/accounts'),
            ])
            console.log(res)
            if (res[0].data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: {
                        status: res[0].data.status,
                        message: res[0].data.data.message,
                        from,
                    },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <AdminContext.Provider
            value={{
                responseStatus: state.responseStatus,
                all_accounts: state.all_accounts,
                all_pg_accounts: state.all_pg_accounts,
                all_sellers: state.all_sellers,
                all_revenue_charges: state.all_revenue_charges,
                all_revenue_charge_fees: state.all_revenue_charge_fees,
                single_account: state.single_account,
                getAccountsList,
                activateAccount,
                getPGAccountsList,
                getAccountDetails,
                updateAccount,
                getSellersList,
                getRevenueCharges,
                uploadCSVAndGenerate,
                getRevenueChargeFees,
                toggleSplitPayment,
                createNewAuctionIOAccount,
                updateAuctionIOAccount,
                deleteAuctionIOAccount,
                clearResponse,
            }}
        >
            {props.children}
        </AdminContext.Provider>
    )
}

export default AdminState
