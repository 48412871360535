import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ACCOUNT_BALANCE,
    GET_ACCOUNT_CUSTOMER,
    GET_ALL_BALANCE,
    RESET_BALANCE,
    GET_ALL_CHARGE,
    GET_ALL_TRANSFER,
    RESET_CHARGES,
    GET_ALL_PAYOUT,
    RESET_PAYOUT,
    GET_ALL_DISPUTES,
    GET_DISPUTE_DETAILS,
    PAGE_COUNT_ADD,
    PAGE_COUNT_SUB,
    PAGE_COUNT_RESET,
    SET_CHARGE_ID,
    SET_CHARGE_DETAILS,
    SET_CUSTOMER_ID,
    SET_CUSTOMER_DETAILS,
    SET_PAYOUT_ID,
    SET_PAYOUT_DETAILS,
    SET_ACCOUNTS,
    SET_ACCOUNT_SOURCE,
    CLEAR_ACCOUNT_SOURCE,
    SET_PAGINATION,
    RESET_PAGINATION,
    SET_PAYMENT_TAB_VALUE,
    SET_LOADING_TRUE,
    SET_LOADING_FALSE,
    GET_SINGLE_CHARGE,
    GET_SINGLE_CUSTOMER,
    GET_SINGLE_TRANSFER,
} from './accountTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case GET_ACCOUNT_BALANCE:
            return {
                ...state,
                accountBalance: action.payload,
            }
        case GET_ACCOUNT_CUSTOMER:
            return {
                ...state,
                all_customers: action.payload,
            }
        case GET_ALL_BALANCE:
            return {
                ...state,
                all_balances: action.payload,
            }
        case RESET_BALANCE:
            return {
                ...state,
                all_balances: {
                    records: [],
                    totalRecords: 0,
                    setDisp: '',
                },
            }
        case GET_ALL_CHARGE:
            return {
                ...state,
                all_charges: action.payload,
            }
        case GET_ALL_TRANSFER:
            return {
                ...state,
                all_transfers: action.payload,
            }
        case GET_SINGLE_CHARGE:
            return {
                ...state,
                single_charge: action.payload,
            }
        case GET_SINGLE_TRANSFER:
            return {
                ...state,
                single_transfer: action.payload,
            }
        case GET_SINGLE_CUSTOMER:
            return {
                ...state,
                single_customer: action.payload,
            }
        case RESET_CHARGES:
            return {
                ...state,
                all_charges: {
                    records: [],
                    totalRecords: 0,
                    setDisp: '',
                },
            }
        case GET_ALL_PAYOUT:
            return {
                ...state,
                all_payouts: action.payload,
            }
        case RESET_PAYOUT:
            return {
                ...state,
                all_payouts: {
                    records: [],
                    totalRecords: 0,
                    setDisp: '',
                },
            }
        case GET_ALL_DISPUTES:
            return {
                ...state,
                all_disputes: action.payload,
            }
        case GET_DISPUTE_DETAILS:
            return {
                ...state,
                disputeDetails: action.payload,
            }
        case PAGE_COUNT_ADD:
            return {
                ...state,
                paginationPage: state.paginationPage + 1,
            }
        case PAGE_COUNT_SUB:
            return {
                ...state,
                paginationPage: state.paginationPage - 1,
            }
        case PAGE_COUNT_RESET:
            return {
                ...state,
                paginationPage: 1,
            }
        case SET_CHARGE_ID:
            return {
                ...state,
                chargeId: action.payload,
                chargeDetails: null,
            }
        case SET_CHARGE_DETAILS:
            return {
                ...state,
                chargeDetails: action.payload,
            }
        case SET_CUSTOMER_ID:
            return {
                ...state,
                customerId: action.payload,
                customerDetails: null,
            }
        case SET_CUSTOMER_DETAILS:
            return {
                ...state,
                customerDetails: action.payload,
            }
        case SET_PAYOUT_ID:
            return {
                ...state,
                payoutId: action.payload,
            }
        case SET_PAYOUT_DETAILS:
            return {
                ...state,
                payoutDetails: action.payload,
            }
        case SET_ACCOUNTS:
            return {
                ...state,
                accounts: action.payload,
            }
        case SET_ACCOUNT_SOURCE:
            return {
                ...state,
                account_source: action.payload,
            }
        case CLEAR_ACCOUNT_SOURCE:
            return {
                ...state,
                account_source: null,
            }
        case SET_PAGINATION:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    pageCount:
                        action.payload.pageCount !== null && action.payload.pageCount !== undefined
                            ? action.payload.pageCount
                            : state.pagination.pageCount,
                    previous:
                        action.payload.previous !== null && action.payload.previous !== undefined
                            ? action.payload.previous
                            : state.pagination.previous,
                    next:
                        action.payload.next !== null && action.payload.next !== undefined
                            ? action.payload.next
                            : state.pagination.next,
                },
            }
        case RESET_PAGINATION:
            return {
                ...state,
                pagination: {
                    pageCount: 0,
                    previous: true,
                    next: false,
                },
            }
        case SET_PAYMENT_TAB_VALUE:
            return {
                ...state,
                paymentTabValue: action.payload,
            }
        case SET_LOADING_TRUE:
            return {
                ...state,
                loading: true,
            }
        case SET_LOADING_FALSE:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}
