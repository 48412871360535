import React from 'react'
import DashboardSidePanel from '../../organisms/DashboardSidePanel'
import DashboardTopPanel from '../../organisms/DashboardTopPanel'
import './DashboardLayout.css'

function DashboardLayout(props) {
    return (
        <div className="dashboardLayout usrDashboard">
            <div className="dashboardLayoutLt">
                <DashboardSidePanel />
            </div>
            <div className="dashboardLayoutRt">
                <DashboardTopPanel />
                <div className="dashboardChild">{props.children}</div>
            </div>
        </div>
    )
}

export default DashboardLayout
