import React, { useContext, useState, useEffect } from 'react'

import PrimaryButton from '../../../components/atoms/PrimaryButton'
import SecondaryButton from '../../../components/atoms/SecondaryButton'
import FullScreenPopup from '../../../components/organisms/FullScreenPopup'

import { LinearProgress } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData } from '../../../common/components'
// import './UserManage.css'
import Loaders from '../../../components/molecules/Loaders'
import { Country as CSCCountry } from 'country-state-city'
import AuthContext from '../../../context/auth/authContext'
import AccountContext from '../../../context/admin/adminContext'

const permission = [
    {
        id: '/payments',
        description: 'Payments',
    },
    // {
    //     id: '/payments/succeeded',
    //     description: 'Succeeded Payments',
    // },
    // {
    //     id: '/payments/refunded',
    //     description: 'Refunded Payments',
    // },
    // {
    //     id: '/payments/failed',
    //     description: 'Failed Payments',
    // },
    {
        id: '/payouts',
        description: 'Payouts',
    },
    {
        id: '/balance',
        description: 'Balance',
    },
    {
        id: '/disputes',
        description: 'Disputes',
    },
    {
        id: '/customers',
        description: 'Customers',
    },
    {
        id: '/subscription',
        description: 'Subscription',
    },
    {
        id: '/invoice',
        description: 'Invoice',
    },
    {
        id: '/report',
        description: 'Reports',
    },
]

const AccountManage = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const data = props.data
    const toggleFullScreenPopup = props.function
    const authContext = useContext(AuthContext)
    const accountContext = useContext(AccountContext)

    const { user } = authContext

    const {
        getAccountDetails,
        single_account,
        updateAccount,
        responseStatus: responseStatusAccount,
    } = accountContext

    const [permissionSelect, setPermissionSelect] = useState(permission)

    const validationArray = [
        Yup.object({
            name: Yup.string()
                .min(2, 'Minimum 2 characters')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
            email: Yup.string()
                .email('Invalid email format')
                .max(250, 'Maximum 250 characters')
                .required('Required'),
            confirm_email:
                data.status === 'new'
                    ? Yup.string()
                          .email('Invalid email format')
                          .oneOf([Yup.ref('email')], "Email's not match")
                          .required('Required!')
                    : Yup.string(),
            country: Yup.string().required('Required'),
        }),
    ]

    useEffect(() => {
        if (user) {
            formik.values.account_id = user.account_id
            formik.values.country = user.country
        }
    }, [user])
    const formik = useFormik({
        initialValues: {
            email: '',
            confirm_email: '',
            name: '',
            permissions: [],
            account_id: null,
            country: 'US',
        },
        validationSchema: validationArray[0],
        onSubmit: async (values) => {
            setIsLoading(true)
            if (data.status == 'edit' && data.id) {
                values.id = data.id
                await updateAccount(values)
            }
        },
    })
    useEffect(() => {
        setIsLoading(false)
    }, [])
    useEffect(() => {
        return () => {
            setIsLoading(false)
            resetFrom()
        }
    }, [])
    useEffect(() => {
        if (data.status === 'edit') {
            setIsLoading(true)
            getAccountDetails({
                id: data.id,
            })
        } else {
            resetFrom()
        }
    }, [data])

    useEffect(() => {
        setIsLoading(false)
        console.log('single_account', single_account)
        if (single_account.records && data.status === 'edit' && data.id) {
            const employee = single_account.records[0]

            formik.values.email = employee.email
            formik.values.name = employee.name
            formik.values.country = employee.country
            formik.values.permissions = employee.permissions.split(',')
        } else {
            resetFrom()
        }
    }, [single_account])

    const resetFrom = () => {
        formik.values.email = ''
        formik.values.confirm_email = ''
        formik.values.name = ''
        formik.values.permissions = []
        formik.values.country = 'US'
        formik.handleReset()
        setIsLoading(false)
    }

    const EmployeeDetails = {
        formik: formik,
        data: [
            {
                label: 'Name',
                name: 'name',
                type: 'text',
                placeholder: 'Enter customer name',
                class: 'col-12 col-sm-6',
            },
            {
                label: 'Email address',
                name: 'email',
                type: 'email',
                placeholder: 'Enter customer email address',
                class: 'col-12 col-sm-6',
                disabled: data.status === 'edit' ? true : false,
            },
            {
                label: 'Confirm email address',
                name: 'confirm_email',
                type: 'email',
                placeholder: 'Confirm email address',
                class: `${data.status == 'edit' ? 'd-none' : ''} col-12 col-sm-6`,
            },
            // {
            //     label: 'Country',
            //     placeholder: 'Select your country',
            //     class: 'col-12 col-sm-6',
            //     type: 'select',
            //     options: CSCCountry.getAllCountries()
            //         .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
            //         .map((country) => {
            //             let busCategoryChanged = {}
            //             busCategoryChanged.show = country.name
            //             busCategoryChanged.value = country.isoCode
            //             return busCategoryChanged
            //         }),
            //     name: 'country',
            //     autoFocus: true,
            // },
        ],
    }

    const formSubmit = (e) => {
        formik.handleSubmit(e)
        console.log(formik.errors)
    }

    useEffect(() => {
        if (responseStatusAccount) {
            if (
                responseStatusAccount.from === 'updateAccount' &&
                responseStatusAccount.status === 'success'
            ) {
                formik.setSubmitting(false)
                setIsLoading(false)
                toggleFullScreenPopup(false)
            } else if (responseStatusAccount.status === 'error') {
                setIsLoading(false)
                toggleFullScreenPopup(false)
            }
        }
    }, [responseStatusAccount])

    const permissionDetails = [
        {
            type: 'check',
            class: 'empPermissions d-flex align-items-center flex-wrap',
            name: 'permissions',
            formik: formik,
            options: permissionSelect,
        },
    ]

    return (
        <FullScreenPopup
            modaltitle={data.status == 'new' ? 'ADD NEW ACCOUNT' : 'EDIT ACCOUNT'}
            open={data.popup == 'Comment' ? false : data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal csmPopScreen">
                <div className="fspBody">
                    {formik.isSubmitting ? <LinearProgress /> : null}
                    {isLoading ? (
                        <div className="fspLoader">
                            <Loaders name="home" isLoading={isLoading} />
                        </div>
                    ) : (
                        <form onSubmit={formSubmit} autoComplete="nofill">
                            <div>
                                <div className="row">{Object.values(mapData(EmployeeDetails))}</div>

                                <h2 className="dashTitle">PERMISSIONS</h2>
                                {Object.values(mapData(permissionDetails))}
                            </div>
                            <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                                <SecondaryButton
                                    label="Cancel"
                                    onClick={() => toggleFullScreenPopup(false)}
                                />
                                <PrimaryButton
                                    btnSize="ml-2"
                                    type="submit"
                                    label={data.status === 'new' ? 'Submit' : 'Update'}
                                />
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default AccountManage
