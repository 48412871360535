import React, { useReducer, useEffect } from 'react'
import TemplateContext from './templateContext'
import TemplateReducer from './templateReducer'
import { apiCall, setAuthToken } from '../../../common/api'
import { response } from '../../common'
import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_TEMPLATES,
    GET_SINGLE_TEMPLATE,
} from './templateTypes'

const TemplateState = (props) => {
    const initialState = {
        responseStatus: null,
        all_templates: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        single_template: {
            record: {},
        },
    }

    const [state, dispatch] = useReducer(TemplateReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllTemplates = async (formData) => {
        try {
            console.log('formData', formData)
            const [res] = await Promise.all([
                apiCall('post', 'getAllTemplates', formData, '', 'admin/templates'),
            ])
            const from = 'getAllTemplates'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_TEMPLATES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleTemplate = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleTemplate', formData, '', 'admin/templates'),
            ])
            const from = 'getSingleTemplate'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_TEMPLATE,
                    payload: {
                        record: res.data.data.responseData.item
                            ? res.data.data.responseData.item
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const templateAction = async (formData) => {
        try {
            const from = 'templateAction'
            const [res] = await Promise.all([
                apiCall('post', 'templateAction', formData, '', 'admin/templates'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    // Clear Response
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <TemplateContext.Provider
            value={{
                responseStatus: state.responseStatus,
                all_templates: state.all_templates,
                single_template: state.single_template,
                templateAction,
                getSingleTemplate,
                getAllTemplates,
                clearResponse,
            }}
        >
            {props.children}
        </TemplateContext.Provider>
    )
}

export default TemplateState
