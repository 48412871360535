import React, { useReducer, useContext } from 'react'
import SearchContext from './searchContext'
import SearchReducer from './searchReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'
import {
    RESPONSE_STATUS,
    SET_PAYMENTS,
    CLEAR_PAYMENTS,
    LOADING_TRUE,
    LOADING_FALSE,
} from './searchTypes'

import AccountContext from '../account/accountContext'

const SearchState = (props) => {
    const accountContext = useContext(AccountContext)
    const { setSearchCustomer, setSearchCharges, paginationPage } = accountContext

    const initialState = {
        loading: false,
        payments: null,
        customers: null,
        accounts: null,
    }

    const [state, dispatch] = useReducer(SearchReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getSearchData = async (formData) => {
        try {
            dispatch({
                type: LOADING_TRUE,
            })
            if (!formData.search == '') {
                if (!Number.isNaN(parseInt(formData.search))) {
                    formData.search = parseFloat(formData.search) * 100
                }
                const [res] = await Promise.all([
                    apiCall('post', 'search', { ...formData, pageNo: paginationPage }, '', 'user'),
                ])
                if (res.data.status === 'success') {
                    if (res.data.data.responseType === 'payments')
                        // dispatch(setSearchCharges(res, paginationPage))
                        dispatch({
                            type: SET_PAYMENTS,
                            payload: res.data.data.responseData.records,
                        })
                    if (res.data.data.responseType === 'customers')
                        dispatch(setSearchCustomer(res, paginationPage))
                    dispatch({
                        type: LOADING_FALSE,
                    })
                }
            } else {
                dispatch({
                    type: CLEAR_PAYMENTS,
                })
                dispatch({
                    type: LOADING_FALSE,
                })
            }
        } catch (err) {
            dispatch({
                type: LOADING_FALSE,
            })
            resp.commonErrorResponse('getCustomer')
        }
    }

    const clearPayments = async () => {
        dispatch({
            type: CLEAR_PAYMENTS,
        })
    }

    return (
        <SearchContext.Provider
            value={{
                loading: state.loading,
                payments: state.payments,
                customers: state.customers,
                accounts: state.accounts,
                getSearchData,
                clearPayments,
            }}
        >
            {props.children}
        </SearchContext.Provider>
    )
}

export default SearchState
