import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <footer className="cstmFooter d-flex align-items-center justify-content-between px-5">
            <p className="m-0">Paymentgateway.com © 2021 | All rights reserved.</p>
            <div className="d-flex align-items-center">
                <Link to="/privacy">Privacy Policy</Link>
                <span className="mx-3">|</span>
                <Link to="/terms">Terms &#38; Conditions</Link>
            </div>
        </footer>
    )
}

export default Footer
