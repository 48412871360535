import { USER_LOADED, LOGOUT, RESPONSE_STATUS, SET_COMPANY_NAME, CLEAR_RESPONSE } from './authTypes'

export default (state, action) => {
    switch (action.type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.data,
            }
        case LOGOUT:
            localStorage.removeItem('token')
            return {
                ...state,
                token: null,
                isAdmin: false,
                isAuthenticated: false,
                loading: false,
                user: {},
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case SET_COMPANY_NAME:
            return {
                ...state,
                user: { ...state.user, company_name: action.payload.data },
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
