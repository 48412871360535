import React from 'react'
//LOGO
export const logo = '/assets/payment_gateway_logo.svg'

// SIDE PANEL LIST
export const SIDE_PANEL = [
    {
        label: [<span className="material-icons-round">home</span>, 'Home'],
        path: '/dashboard',
        type: 'single',
    },
    {
        label: [<span className="material-icons-round">credit_card</span>, 'Payments'],
        path: '/payments',
        type: 'multiple',
        subLinks: [
            {
                label: 'All',
                path: '/payments',
            },
            {
                label: 'Succeeded',
                path: '/payments/succeeded',
            },
            {
                label: 'Refunded',
                path: '/payments/refunded',
            },
            {
                label: 'Failed',
                path: '/payments/failed',
            },
            // {
            //     label: 'Disputes',
            //     path: '/payments/disputes',
            // },
        ],
    },
    {
        label: [<span className="material-icons-round">credit_card</span>, 'Disputes'],
        path: '/disputes',
        type: 'single',
    },
    {
        label: [<span className="material-icons-round">north_east</span>, 'Payouts'],
        path: '/payouts',
        type: 'single',
    },
    // {
    //     label: [<span className="material-icons-round">north_east</span>, 'Transfers'],
    //     path: '/transfers',
    //     type: 'single',
    // },
    {
        label: [<span className="material-icons-round">account_balance_wallet</span>, 'Balance'],
        path: '/balance',
        type: 'single',
    },
    {
        label: [<span className="material-icons-round">account_circle</span>, 'Customers'],
        path: '/customers',
        type: 'single',
    },
    {
        label: [<span className="material-icons-round">library_add</span>, 'Subscription'],
        path: '/subscription',
        type: 'single',
    },
    {
        label: [<span className="material-icons-round">receipt</span>, 'Invoice'],
        path: '/invoice',
        type: 'single',
    },
    {
        label: [
            <span className="material-icons-round">manage_accounts</span>,
            'Employee Management',
        ],
        path: '/employeeDashboard',
        type: 'single',
    },
    // {
    //     label: [<span className="material-icons-round">group</span>, 'Vendor'],
    //     path: '/vendors',
    //     type: 'single',
    // },
    {
        label: [<span className="material-icons-round">call_split</span>, 'Split Payment'],
        path: '/splitPayment',
        type: 'single',
    },
    {
        label: [
            <span className="material-icons-outlined">account_tree</span>,
            'Connected Accounts',
        ],
        path: '/connectedAccounts',
        type: 'single',
    },
    {
        label: [<span className="material-icons-round">receipt_long</span>, 'Reports'],
        path: '/report',
        type: 'single',
    },
    {
        label: [<span className="material-icons-round">settings</span>, 'Settings'],
        path: '/settings',
        type: 'single',
    },
]

export const SIDE_PANEL_END_SELLER = [
    {
        label: [<span className="material-icons-round">home</span>, 'Home'],
        path: '/dashboard',
        type: 'single',
    },
    {
        label: [<span className="material-icons-round">credit_card</span>, 'Payments'],
        path: '/payments',
        type: 'multiple',
        subLinks: [
            {
                label: 'All',
                path: '/payments',
            },
            {
                label: 'Payouts',
                path: '/payouts',
            },
            {
                label: 'All Transactions',
                path: '/payments/allTransactions',
            },
        ],
    },
    {
        label: [<span className="material-icons-round">account_balance_wallet</span>, 'Balance'],
        path: '/balance',
        type: 'single',
    },
    {
        label: [<span className="material-icons-round">receipt_long</span>, 'Reports'],
        path: '/report',
        type: 'single',
    },
    // {
    //     label: [<span className="material-icons-round">settings</span>, 'Settings'],
    //     path: '/settings',
    //     type: 'single',
    // },
]

export const SIDE_PANEL_ADMIN = [
    {
        label: [<span className="material-icons-round">people_alt</span>, 'Accounts'],
        path: '/admin/accounts',
        type: 'multiple',
        subLinks: [
            {
                label: 'All',
                path: '/admin/accounts',
            },
            {
                label: 'Deactive',
                path: '/admin/accounts/deactive',
            },
        ],
    },
    // {
    //     label: [<span className="material-icons-round">people_alt</span>, 'Templates'],
    //     path: '/admin/templates',
    //     type: 'single',
    // },
    {
        label: [<span className="material-icons-round">account_circle</span>, 'Customers'],
        path: '/admin/customers',
        type: 'single',
    },
    {
        label: [<span className="material-icons-round">local_atm</span>, 'Disputes'],
        path: '/admin/disputes',
        type: 'single',
    },
    {
        label: [<span className="material-icons-round">credit_card</span>, 'Payments'],
        path: '/admin/payments',
        type: 'multiple',
        subLinks: [
            {
                label: 'All',
                path: '/admin/payments',
            },
            {
                label: 'Succeeded',
                path: '/admin/payments/succeeded',
            },
            {
                label: 'Refunded',
                path: '/admin/payments/refunded',
            },
            {
                label: 'Failed',
                path: '/admin/payments/failed',
            },
        ],
    },
    {
        label: [<span className="material-icons-round">monetization_on</span>, 'Revenue'],
        path: '/admin/revenue',
        type: 'single',
    },
    // {
    //     label: [
    //         <span className="material-icons-outlined">account_tree</span>,
    //         'Connected Accounts',
    //     ],
    //     path: '/admin/connectedAccounts',
    //     type: 'single',
    // },
    // {
    //     label: [<span className="material-icons-round">credit_card</span>, 'Auction IO'],
    //     path: '/admin/auctionIO',
    //     type: 'multiple',
    //     subLinks: [
    //         {
    //             label: 'Accounts',
    //             path: '/admin/auctionIO',
    //         },
    //         {
    //             label: 'Settings',
    //             path: '/admin/auctionIO/settings',
    //         },
    //     ],
    // },
    {
        label: [<span className="material-icons-round">account_circle</span>, 'Settings'],
        path: '/admin/settings',
        type: 'multiple',
        subLinks: [
            {
                label: '2FA',
                path: '/admin/settings/twofactorauth',
            },
        ],
    },
]
