import {
    SET_INVOICE_LIST,
    RESET_INVOICE_LIST,
    SET_SUBSCRIPTION_LIST,
    SET_CUSTOMER_SOURCE,
    RESET_SUBSCRIPTION_LIST,
    GET_ALL_CUSTOMER,
    SET_NEW_CUSTOMER,
    SET_CUSTOMER_BY_ID,
    RESET_PAGINATION,
    SET_PAGINATION,
    SET_INVOICE_ITEMS,
    SET_TAX_COUPONS,
    SET_LOADING_TRUE,
    SET_LOADING_FALSE,
    CLEAR_RESPONSE,
    SET_RESPONSE,
    GET_ACCOUNT_CUSTOMER,
    SET_CUSTOMIZE_INVOICE,
} from './customerTypes'

export default (state, action) => {
    switch (action.type) {
        case SET_INVOICE_LIST:
            return {
                ...state,
                invoiceList: action.payload,
            }
        case RESET_INVOICE_LIST:
            return {
                ...state,
                invoiceList: null,
            }
        case SET_SUBSCRIPTION_LIST:
            return {
                ...state,
                subscriptionList: action.payload,
            }
        case SET_CUSTOMER_SOURCE:
            return {
                ...state,
                customerSourceList: action.payload,
            }
        case RESET_SUBSCRIPTION_LIST:
            return {
                ...state,
                subscriptionList: null,
            }
        case GET_ALL_CUSTOMER:
            return {
                ...state,
                all_customers: action.payload,
            }
        case GET_ACCOUNT_CUSTOMER:
            return {
                ...state,
                all_account_customers: action.payload,
            }
        case SET_NEW_CUSTOMER:
            return {
                ...state,
                newCustomer: { ...state.newCustomer, customer_id: action.payload },
            }
        case SET_CUSTOMER_BY_ID:
            return {
                ...state,
                newCustomer: { ...state.newCustomer, customer: action.payload },
                all_customers: {
                    ...state.all_customers,
                    records: [action.payload, ...state.all_customers.records],
                },
            }
        case SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload,
            }
        case RESET_PAGINATION:
            return {
                ...state,
                pagination: {
                    pageCount: 0,
                    previous: true,
                    next: false,
                },
            }
        case SET_INVOICE_ITEMS:
            return {
                ...state,
                all_invoice_items: action.payload,
            }
        case SET_TAX_COUPONS:
            return {
                ...state,
                tax_coupons: action.payload,
            }
        case SET_LOADING_TRUE:
            return {
                ...state,
                loading: true,
            }
        case SET_LOADING_FALSE:
            return {
                ...state,
                loading: false,
            }
        case SET_RESPONSE:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case SET_CUSTOMIZE_INVOICE:
            return {
                ...state,
                customize_invoice: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
