import React, { useContext, useState, useEffect } from 'react'
import _ from 'underscore'
import DashboardLayout from '../../components/templates/DashboardLayout'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'

import CustomTable from '../../components/molecules/CustomTable'
import CustomSearch from '../../components/molecules/CustomSearch'
import CustomDialog from '../../components/organisms/Dialog'
import AdminContext from '../../context/admin/adminContext'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import { handleRedirectInternal } from '../../common/components'
import Loaders from '../../components/molecules/Loaders'
import AdminLayout from '../../components/templates/AdminLayout'
import AccountManage from './Accounts/view'
import ReportContext from '../../context/Report/reportContext'

function Customers(props) {
    const history = useHistory()
    const [tableBody, setTableBody] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [onDialogOpen, setOnDialogOpen] = useState(false)

    const adminContext = useContext(AdminContext)
    const reportContext = useContext(ReportContext)

    const {
        activateAccount,
        getAccountsList,
        all_accounts,
        responseStatus: responseStatusAccount,
    } = adminContext
    const { getAllExportDownload } = reportContext

    console.log(props.match.params)
    let action = props.match.params.action || 'active'

    const onChangeDialog = () => {
        setOnDialogOpen(!onDialogOpen)
    }

    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
        user_type: 0,
    })

    const toggleFullScreenPopup = (popup, status, id, user_type) => {
        if (popup) {
            setManage({ popup, status, id, user_type })
        } else {
            setManage({ popup, status: 'new', id: 0, user_type: 0 })
        }
    }

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: 'desc',
            orderby: 'ac.id',
            filters: {
                searchterm: {
                    value: '',
                    type: 'like',
                    field: 'ac.email',
                },
                searchname: {
                    value: '',
                    type: 'like',
                    field: 'ac.name',
                },
                searchid: {
                    value: '',
                    type: 'like',
                    field: 'ac.account_id',
                },
            },
            action: {
                value: '',
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAccountsList(values)
        },
    })

    const actionFormik = useFormik({
        initialValues: {
            id: '',
            status: '',
        },
        onSubmit: (values) => {
            console.log(values)
            activateAccount(values)
        },
    })

    useEffect(() => {
        console.log('all_accounts', all_accounts)
        if (all_accounts) {
            setTableBody(all_accounts.records.length ? all_accounts.records : [])
        }
        setIsLoading(false)
    }, [all_accounts])

    const onClickEdit = (id, secondaryID, type) => {
        console.log('pp', id)
        toggleFullScreenPopup(true, 'edit', id)
    }

    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.page = 1
        formik.values.filters.searchterm.value = ''
        formik.values.filters.searchname.value = ''
        formik.values.filters.searchid.value = ''
        formik.values.action.value = action

        getAccountsList(formik.values)
        return () => {
            setTableBody([])
        }
    }, [action])

    const onClickSwitchUser = (id, type) => {
        console.log('switchuser', id, type)
        let btoa = Buffer.from(id.toString(), 'binary').toString('base64')
        window.open(`${process.env.REACT_APP_FRONTEND_URL}/switch?account_id=${btoa}`, '_blank')
    }

    const onClickChangeStatus = (id) => {
        actionFormik.values.id = id
        actionFormik.values.status = 'active'
        onChangeDialog()
    }

    const tableValues = []

    if (action === 'deactive') {
        tableValues.push(
            {
                field: 'id',
                type: '',
                firstChild: false,
                sort: true,
                sortField: 'ac.id',
                disablePadding: false,
                label: 'ID',
            },
            {
                field: 'email',
                type: '',
                firstChild: false,
                sort: true,
                sortField: 'ac.email',
                disablePadding: false,
                label: 'Email',
            },
            {
                field: 'account_type',
                type: 'text',
                firstChild: false,
                sort: true,
                sortField: 'ac.account_type',
                disablePadding: false,
                label: 'Account Type',
            },
            {
                field: 'created_at',
                type: 'day',
                firstChild: false,
                sort: true,
                sortField: 'ac.created_at',
                disablePadding: false,
                label: 'Created At',
            },
            {
                field: 'status',
                type: 'text',
                firstChild: false,
                sort: true,
                sortField: 'ac.status',
                disablePadding: false,
                label: 'Status',
            },
            {
                field: 'action',
                type: 'button',
                clickType: 'changestatus',
                clickID: 'id',
                onclick: onClickChangeStatus,
                buttonType: 'visibility',
                label: 'Activate',
                tooltipTitle: 'Activate account',
            },
        )
    } else {
        tableValues.push(
            {
                field: 'account_id',
                type: '',
                firstChild: false,
                sort: true,
                sortField: 'ac.account_id',
                disablePadding: false,
                label: 'Account ID',
            },
            {
                field: 'email',
                type: '',
                firstChild: false,
                sort: true,
                sortField: 'ac.email',
                disablePadding: false,
                label: 'Email',
            },
            {
                field: 'fees_percent',
                type: 'text',
                firstChild: false,
                sort: true,
                sortField: 'ac.fees_percent',
                disablePadding: false,
                label: 'Fee Percent',
            },
            {
                field: 'account_of',
                type: 'text',
                firstChild: false,
                sort: true,
                sortField: 'ac.account_of',
                disablePadding: false,
                label: 'Account Of',
            },
            // {
            //     field: 'created_at',
            //     type: 'datetime',
            //     sort: true,
            //     sortField: 'ac.created_at',
            //     firstChild: false,
            //     disablePadding: false,
            //     label: 'Date',
            // },
            {
                field: 'action',
                type: 'button',
                buttonType: 'visibility',
                clickType: 'edit',
                clickID: 'id',
                onclick: onClickEdit,
                sort: false,
                firstChild: false,
                disablePadding: false,
                label: 'Action',
                tooltipTitle: 'View Account',
            },
            {
                field: 'action',
                type: 'button',
                clickType: 'switchuser',
                clickID: 'account_id',
                onclick: onClickSwitchUser,
                buttonType: 'visibility',
                sort: false,
                firstChild: false,
                disablePadding: false,
                label: 'Switch user',
                tooltipTitle: 'Switch user',
            },
        )
    }

    useEffect(() => {
        if (responseStatusAccount) {
            if (responseStatusAccount.from === 'activateAccount') {
                onChangeDialog()
                actionFormik.values.id = ''
                actionFormik.values.status = ''
                getAccountsList(formik.values)
            }
        }
    }, [responseStatusAccount])

    const tableActions = []

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Email',
                placeholder: 'Enter account email',
                class: 'col-md-3 col-sm-6 col-12',
                type: 'text',
                name: 'searchterm',
                filter: true,
            },
            // {
            //     label: 'Name',
            //     placeholder: 'Enter account name',
            //     class: 'col-md-3 col-sm-6 col-12',
            //     type: 'text',
            //     name: 'searchname',
            //     filter: true,
            // },
            {
                label: 'Account Id',
                placeholder: 'Enter account id',
                class: 'col-md-3 col-sm-6 col-12',
                type: 'text',
                name: 'searchid',
                filter: true,
            },
        ],
    }

    return (
        <AdminLayout>
            <CustomSearch searchInfo={searchInfo} formik={formik} />

            <div className="dashboardHead d-flex align-items-center justify-content-between mb-5">
                <SecondaryButton
                    onClick={() =>
                        getAllExportDownload({
                            action: 'export',
                            order: 'asc',
                            orderby: '',
                            search: 'all',
                            report: `${action}_product_management`,
                        })
                    }
                >
                    <span className="material-icons">get_app</span>Export All Data
                </SecondaryButton>
            </div>
            {isLoading ? (
                <Loaders name="table" isLoading={isLoading} />
            ) : (
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_accounts}
                    changeTableFunction={getAccountsList}
                />
            )}
            <AccountManage data={manage} function={toggleFullScreenPopup} />
            <CustomDialog
                actions
                open={onDialogOpen}
                onClose={onChangeDialog}
                title={'Activate account'}
                cancel={'Cancel'}
                handleCancel={onChangeDialog}
                ok={'Activate'}
                handleSubmit={actionFormik.handleSubmit}
            >
                Are you sure you want to activate this account?
            </CustomDialog>
        </AdminLayout>
    )
}

export default Customers
