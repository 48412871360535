import { SET_PAYMENTS, CLEAR_PAYMENTS, LOADING_TRUE, LOADING_FALSE } from './searchTypes'

export default (state, action) => {
    switch (action.type) {
        case SET_PAYMENTS:
            return {
                ...state,
                payments: action.payload,
            }
        case LOADING_TRUE:
            return {
                ...state,
                loading: true,
            }
        case LOADING_FALSE:
            return {
                ...state,
                loading: false,
            }
        case CLEAR_PAYMENTS:
            return {
                ...state,
                payments: null,
            }
        default:
            return state
    }
}
