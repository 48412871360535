import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import PrimaryButton from '../../atoms/PrimaryButton'
import SecondaryButton from '../../atoms/SecondaryButton'

export default function FormDialog(props) {
    const open = props.open
    const openFunction = props.function
    const title = props.title
    return (
        <div>
            <Dialog
                className={`customDialog ${props.className}`}
                open={open}
                fullWidth={true}
                maxWidth={props.maxWidth}
                scroll={props.scroll}
                onClose={openFunction}
                aria-labelledby="form-dialog-title"
                disableBackdropClick={props.disableBackdropClick ? true : false}
            >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent dividers>{props.children}</DialogContent>
                {props.actions && (
                    <DialogActions>
                        <SecondaryButton
                            label={props.cancel}
                            onClick={props.handleCancel}
                            btnSize="small"
                        />
                        <PrimaryButton
                            label={props.ok}
                            onClick={props.handleSubmit}
                            btnSize="small"
                        />
                    </DialogActions>
                )}
            </Dialog>
        </div>
    )
}
