import React, { useReducer } from 'react'
import SettingsContext from './settingsContext'
import SettingsReducer from './settingsReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'
import {
    SET_RESPONSE_STATUS,
    CLEAR_RESPONSE,
    SET_BUSINESS_DETAILS,
    SET_AUTH_URI,
    CLEAR_AUTH_URI,
    SET_QUICKBOOKS_TOKEN,
    CLEAR_QUICKBOOKS_TOKEN,
    SET_VIRTUAL_CARDS,
} from './settingsTypes'

const SettingsState = (props) => {
    const initialState = {
        responseStatus: null,
        businessDetails: null,
        authUri: null,
        quickbooksToken: null,
        virtualCards: null,
    }
    const [state, dispatch] = useReducer(SettingsReducer, initialState)
    let resp = new response(dispatch, SET_RESPONSE_STATUS)

    const retrieveBusinessDetails = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'retrieveBusinessDetails', formData, '', 'auth'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_BUSINESS_DETAILS,
                    payload: {
                        logo: res?.data?.data?.responseData?.settings?.branding?.logo
                            ? res?.data?.data?.responseData?.settings?.branding?.logo
                            : '',
                        name: res?.data?.data?.responseData?.business_profile?.name
                            ? res?.data?.data?.responseData?.business_profile?.name
                            : '',
                        domain: res?.data?.data?.responseData?.business_profile?.url
                            ? res?.data?.data?.responseData?.business_profile?.url
                            : '',
                        primaryColor: res?.data?.data?.responseData?.settings?.branding
                            ?.primary_color
                            ? res?.data?.data?.responseData?.settings?.branding?.primary_color
                            : '',
                        secondaryColor: res?.data?.data?.responseData?.settings?.branding
                            ?.secondary_color
                            ? res?.data?.data?.responseData?.settings?.branding?.secondary_color
                            : '',
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('retrieveBusinessDetails')
        }
    }

    const updateBusinessDetails = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateBusinessDetails', formData, '', 'auth'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_BUSINESS_DETAILS,
                    payload: {
                        logo: res.data.data.responseData.settings.branding.logo,
                        name: res.data.data.responseData.business_profile.name,
                        domain: res.data.data.responseData.business_profile.url,
                        primaryColor: res.data.data.responseData.settings.branding.primary_color,
                        secondaryColor:
                            res.data.data.responseData.settings.branding.secondary_color,
                    },
                })
                dispatch({
                    type: SET_RESPONSE_STATUS,
                    payload: { status: res.data.status, message: res.data.data.message },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('updateBusinessDetails')
        }
    }

    const connectToQuickbooks = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'authUri', formData, '', 'quickbooks'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_AUTH_URI,
                    payload: res.data.data.responseData.authUri,
                })
            }
        } catch (err) {
            resp.commonErrorResponse('authUri')
        }
    }

    const createQuickbooksToken = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'callback', formData, '', 'quickbooks'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_QUICKBOOKS_TOKEN,
                    payload: res.data.data.responseData,
                })
                clearAuthUri()
            }
        } catch (err) {
            resp.commonErrorResponse('createQuickbooksToken')
        }
    }

    const clearQuickbooksToken = async (formData) => {
        dispatch({
            type: CLEAR_QUICKBOOKS_TOKEN,
        })
    }

    const getVirtualCardsList = async () => {
        try {
            const [res] = await Promise.all([apiCall('post', 'listAllCards', '', '', 'issuing')])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_VIRTUAL_CARDS,
                    payload: res.data.data.responseData.data,
                })
            }
        } catch (err) {
            resp.commonErrorResponse('getVirtualCardsList')
        }
    }

    const retrieveVirtualCard = async (card_id) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'retrieveCards', { card_id }, '', 'issuing'),
            ])
            if (res.data.status === 'success') {
                let payload = state.virtualCards,
                    data = res.data.data.responseData,
                    index = payload.map((card) => card.id).indexOf(data.id)

                if (index !== undefined) payload.splice(index, 1, data)

                dispatch({
                    type: SET_VIRTUAL_CARDS,
                    payload,
                })
            }
        } catch (err) {
            resp.commonErrorResponse('retrieveVirtualCard')
        }
    }

    const clearAuthUri = () => {
        dispatch({
            type: CLEAR_AUTH_URI,
        })
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <SettingsContext.Provider
            value={{
                responseStatus: state.responseStatus,
                businessDetails: state.businessDetails,
                authUri: state.authUri,
                quickbooksToken: state.quickbooksToken,
                virtualCards: state.virtualCards,
                clearResponse,
                retrieveBusinessDetails,
                updateBusinessDetails,
                connectToQuickbooks,
                createQuickbooksToken,
                clearAuthUri,
                clearQuickbooksToken,
                getVirtualCardsList,
                retrieveVirtualCard,
            }}
        >
            {props.children}
        </SettingsContext.Provider>
    )
}

export default SettingsState
