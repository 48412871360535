import React, { useReducer } from 'react'
import SplitpaymentContext from './splitpaymentContext'
import SplitpaymentReducer from './splitpaymentReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'
import { SET_RESPONSE_STATUS, CLEAR_RESPONSE_STATUS, SET_SELLERS_LIST } from './splitpaymentTypes'

const splitpaymentState = (props) => {
    const initialState = {
        responseStatus: null,
        sellersList: null,
    }
    const [state, dispatch] = useReducer(SplitpaymentReducer, initialState)
    let resp = new response(dispatch, SET_RESPONSE_STATUS)

    const getSellersList = async () => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSellersList', '', '', 'splitpayment'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_SELLERS_LIST,
                    payload: res.data.data.responseData,
                })
            }
        } catch (err) {
            resp.commonErrorResponse('getSellersList')
        }
    }
    const updateSellersList = async (formData) => {
        try {
            const [res] = await Promise.all(
                formData.map(
                    async (data) =>
                        await apiCall('post', 'updateSellersList', data, '', 'splitpayment'),
                ),
            )
            if (res.data.status === 'success') {
                getSellersList()
                dispatch({
                    type: SET_RESPONSE_STATUS,
                    payload: {
                        status: 'success',
                        message: 'Updated successfully',
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('updateSellersList')
        }
    }
    const disableSellersList = async (formData, callback) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'disableSellersList', formData, '', 'splitpayment'),
            ])
            if (res.data.status === 'success') {
                callback()
                if (res.data.data.responseData.affectedRows > 0) {
                    dispatch({
                        type: SET_RESPONSE_STATUS,
                        payload: {
                            status: 'success',
                            message: 'Updated successfully',
                        },
                    })
                }
            }
        } catch (err) {
            resp.commonErrorResponse('updateSellersList')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE_STATUS,
        })

    return (
        <SplitpaymentContext.Provider
            value={{
                responseStatus: state.responseStatus,
                sellersList: state.sellersList,
                clearResponse,
                getSellersList,
                updateSellersList,
                disableSellersList,
            }}
        >
            {props.children}
        </SplitpaymentContext.Provider>
    )
}

export default splitpaymentState
