import React, { useReducer } from 'react'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    COMMON_VALUES,
    BID_HISTORY_VALUE,
    US_STATE_VALUE,
    ALL_COUNTRY_VALUE,
    CLEAR_SEARCH,
    GET_STATIC_PAGE,
    CLEAR_BID_HISTORY,
    SEARCH_VALUE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    SET_PROFILE_DATA,
    FILE_DETAILS,
    CLEAR_FILE_DETAILS,
} from './commonTypes'

import AuthContext from '../auth/authContext'

const CommonState = (props) => {
    const initialState = {
        responseStatus: null,
        configVariable: [],
        searchValue: null,
        bidHistoryValue: 0,
        USStates: [],
        fileDetails: null,
        AllCountries: [],
        static_page: {
            record: {},
        },
        profile: null,
    }

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getGlobalVariable = async (type) => {
        const [res] = await Promise.all([apiCall('post', 'getDefault', {}, '', 'common')])

        if (res.data.status === 'success') {
            dispatch({
                type: COMMON_VALUES,
                payload: {
                    configVariable: res.data.data.responseData.configVariable,
                },
            })
        }
    }

    const getStaticPage = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getStaticPage', formData, '', 'common'),
            ])
            const from = 'StaticPage'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_STATIC_PAGE,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const setUSState = async (data) => {
        dispatch({
            type: US_STATE_VALUE,
            payload: data,
        })
    }

    const setAllCountry = async (data) => {
        dispatch({
            type: ALL_COUNTRY_VALUE,
            payload: data,
        })
    }

    const setSearchValue = async (data) => {
        dispatch({
            type: SEARCH_VALUE,
            payload: data,
        })
    }

    const setBidHistoryValue = async (data) => {
        dispatch({
            type: BID_HISTORY_VALUE,
            payload: data,
        })
    }

    const setProfileData = async (data) => {
        dispatch({
            type: SET_PROFILE_DATA,
            payload: data,
        })
    }

    const getProfileDetails = async () => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getProfileDetails', '', '', 'profile'),
            ])
            if (res.data.status === 'success') {
                setProfileData(res.data.data.responseData)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updateProfile = async (formData, changeProfile, resetForm) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateProfile', formData, '', 'profile'),
            ])
            if (res.data.status === 'success') {
                setProfileData(res.data.data.responseData)
                changeProfile()
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: { status: res.data.status, message: res.data.data.message },
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const changePassword = async (formData, closeDialog, resetForm) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'changePassword', formData, '', 'profile'),
            ])
            closeDialog()
            resetForm()
            if (res.data.status === 'success') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: { status: res.data.status, message: res.data.data.message },
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const createStripeFile = async (file, purpose) => {
        try {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('purpose', purpose)

            const headers = {
                'Content-Type': 'multipart/form-data',
            }

            const res = await apiCall('post', 'createFile', formData, headers, 'common')
            if (res.data.status === 'success') {
                dispatch({
                    type: FILE_DETAILS,
                    payload: {
                        fileID: res.data.data.responseData.id,
                        fileName: res.data.data.responseData.filename,
                    },
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearBidHistoryValue = () =>
        dispatch({
            type: CLEAR_BID_HISTORY,
        })

    const clearSearchValue = () =>
        dispatch({
            type: CLEAR_SEARCH,
        })

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const clearFileDetails = () => {
        dispatch({
            type: CLEAR_FILE_DETAILS,
        })
    }

    return (
        <CommonContext.Provider
            value={{
                configVariable: state.configVariable,
                searchValue: state.searchValue,
                static_page: state.static_page,
                bidHistoryValue: state.bidHistoryValue,
                responseStatus: state.responseStatus,
                USStates: state.USStates,
                AllCountries: state.AllCountries,
                profile: state.profile,
                fileDetails: state.fileDetails,
                clearFileDetails,
                getGlobalVariable,
                setSearchValue,
                clearSearchValue,
                setBidHistoryValue,
                clearBidHistoryValue,
                getStaticPage,
                setUSState,
                setAllCountry,
                createStripeFile,
                clearResponse,
                getProfileDetails,
                updateProfile,
                changePassword,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
