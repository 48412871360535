import { SET_RESPONSE_STATUS, CLEAR_RESPONSE_STATUS, SET_SELLERS_LIST } from './splitpaymentTypes'

export default (state, action) => {
    switch (action.type) {
        case SET_RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: '',
            }
        case SET_SELLERS_LIST:
            return {
                ...state,
                sellersList: action.payload,
            }
        default:
            return state
    }
}
