// for common data
export const COMMON_VALUES = 'COMMON_VALUES'
export const SEARCH_VALUE = 'SEARCH_VALUE'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
export const US_STATE_VALUE = 'US_STATE_VALUE'
export const ALL_COUNTRY_VALUE = 'ALL_COUNTRY_VALUE'
export const BID_HISTORY_VALUE = 'BID_HISTORY_VALUE'
export const CLEAR_BID_HISTORY = 'CLEAR_BID_HISTORY'
export const GET_STATIC_PAGE = 'GET_STATIC_PAGE'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA'
export const FILE_DETAILS = 'FILE_DETAILS'
export const CLEAR_FILE_DETAILS = 'CLEAR_FILE_DETAILS'
