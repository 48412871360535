import React, { useReducer } from 'react'
import AccountContext from './accountContext'
import AccountReducer from './accountReducer'
import { apiCall, setAuthToken } from '../../common/api'
import { response } from '../common'
import _ from 'underscore'

import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ACCOUNT_BALANCE,
    GET_ACCOUNT_CUSTOMER,
    GET_ALL_BALANCE,
    RESET_BALANCE,
    GET_ALL_CHARGE,
    RESET_CHARGES,
    GET_ALL_PAYOUT,
    RESET_PAYOUT,
    GET_DISPUTE_DETAILS,
    GET_ALL_DISPUTES,
    PAGE_COUNT_ADD,
    PAGE_COUNT_SUB,
    PAGE_COUNT_RESET,
    SET_CHARGE_ID,
    SET_CHARGE_DETAILS,
    SET_CUSTOMER_ID,
    SET_CUSTOMER_DETAILS,
    SET_PAYOUT_ID,
    SET_PAYOUT_DETAILS,
    SET_ACCOUNTS,
    SET_ACCOUNT_SOURCE,
    CLEAR_ACCOUNT_SOURCE,
    SET_PAGINATION,
    RESET_PAGINATION,
    SET_PAYMENT_TAB_VALUE,
    SET_LOADING_TRUE,
    SET_LOADING_FALSE,
    GET_SINGLE_CHARGE,
    GET_SINGLE_CUSTOMER,
    GET_ALL_TRANSFER,
    GET_SINGLE_TRANSFER,
} from './accountTypes'

const AuctionState = (props) => {
    const initialState = {
        responseStatus: null,
        accountBalance: null,
        all_customers: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_balances: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_charges: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_transfers: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_payouts: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_disputes: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        paginationPage: 1,
        chargeId: null,
        chargeDetails: null,
        customerId: null,
        customerDetails: null,
        disputeDetails: null,
        payoutId: null,
        payoutDetails: null,
        accounts: null,
        account_source: null,
        paymentTabValue: 0,
        pagination: {
            pageCount: 0,
            previous: true,
            next: false,
        },
        single_charge: {
            record: {},
        },
        single_transfer: {
            record: {},
        },
        single_customer: {
            record: {},
        },
        loading: false,
    }

    const [state, dispatch] = useReducer(AccountReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const createExternalAccount = async (formData) => {
        if (localStorage.token) {
            setAuthToken(localStorage.token)
        }
        try {
            const [res] = await Promise.all([
                apiCall('post', 'linkExternalAccount', formData, '', 'account'),
            ])
            resp.commonResponse(res.data, 'linkExternalAccount')
        } catch (err) {
            resp.commonErrorResponse('linkExternalAccount')
        }
    }

    const getDashboardBalanceWithRefund = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getDashboardBalanceWithRefund', formData, '', 'account'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ACCOUNT_BALANCE,
                    payload: res.data.data.responseData,
                })
            } else {
                resp.commonResponse(res.data, 'getDashboardBalanceWithRefund')
            }
        } catch (err) {
            resp.commonErrorResponse('getDashboardBalanceWithRefund')
        }
    }

    const getDashboardBalance = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getDashboardBalance', formData, '', 'account'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ACCOUNT_BALANCE,
                    payload: res.data.data.responseData,
                })
            } else {
                resp.commonResponse(res.data, 'getDashboardBalance')
            }
        } catch (err) {
            resp.commonErrorResponse('getDashboardBalance')
        }
    }

    const getCustomer = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getCustomersList', formData, '', 'customers'),
            ])
            const from = 'getCustomersList'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ACCOUNT_CUSTOMER,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleCustomer = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleCustomer', formData, '', 'customers'),
            ])
            const from = 'getSingleCustomer'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_CUSTOMER,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    // const getCustomer = async (formData) => {
    //     console.log('formdata', formData)
    //     try {
    //         const [res] = await Promise.all([
    //             apiCall('post', 'getCustomersList', formData, '', 'customers'),
    //         ])
    //         if (res.data.status === 'success') {
    //             dispatch({
    //                 type: GET_ACCOUNT_CUSTOMER,
    //                 payload: {
    //                     records: res.data.data.responseData.records
    //                         ? res.data.data.responseData.records
    //                         : [],
    //                     has_more: res.data.data.responseData.has_more
    //                         ? res.data.data.responseData.has_more
    //                         : null,
    //                 },
    //             })
    //             if (formData.starting_after) {
    //                 dispatch({
    //                     type: SET_PAGINATION,
    //                     payload: {
    //                         pageCount: state.pagination.pageCount + 1,
    //                         previous: false,
    //                         next: !res.data.data.responseData.has_more,
    //                     },
    //                 })
    //             } else if (formData.ending_before) {
    //                 dispatch({
    //                     type: SET_PAGINATION,
    //                     payload: {
    //                         pageCount: state.pagination.pageCount - 1,
    //                         previous: state.pagination.pageCount <= 1 ? true : false,
    //                         next: false,
    //                     },
    //                 })
    //             } else {
    //                 dispatch({
    //                     type: SET_PAGINATION,
    //                     payload: {
    //                         next: !res.data.data.responseData.has_more,
    //                     },
    //                 })
    //             }
    //         } else {
    //             resp.commonResponse(res.data, 'getCustomer')
    //         }
    //     } catch (err) {
    //         resp.commonErrorResponse('getCustomer')
    //     }
    // }

    const setSearchCustomer = async (res) => {
        dispatch({
            type: GET_ACCOUNT_CUSTOMER,
            payload: {
                records: res.data.data.responseData.records
                    ? res.data.data.responseData.records
                    : [],
                has_more: res.data.data.responseData.has_more
                    ? res.data.data.responseData.has_more
                    : null,
            },
        })
    }

    const getCustomerById = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getCustomerById', formData, '', 'account'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_CUSTOMER_DETAILS,
                    payload: res.data.data.responseData.records,
                })
            } else {
                resp.commonResponse(res.data, 'getCustomerById')
            }
        } catch (err) {
            resp.commonErrorResponse('getCustomerById')
        }
    }

    const getBalance = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getBalance', formData, '', 'account'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_BALANCE,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        hasMore: res.data.data.responseData.hasMore,
                    },
                })
                if (formData.starting_after) {
                    dispatch({
                        type: SET_PAGINATION,
                        payload: {
                            pageCount: state.pagination.pageCount + 1,
                            previous: false,
                            next: !res.data.data.responseData.hasMore,
                        },
                    })
                } else if (formData.ending_before) {
                    dispatch({
                        type: SET_PAGINATION,
                        payload: {
                            pageCount: state.pagination.pageCount - 1,
                            previous: state.pagination.pageCount <= 1 ? true : false,
                            next: false,
                        },
                    })
                } else {
                    dispatch({
                        type: SET_PAGINATION,
                        payload: {
                            next: !res.data.data.responseData.hasMore,
                        },
                    })
                }
            } else {
                resp.commonResponse(res.data, 'getBalance')
            }
        } catch (err) {
            resp.commonErrorResponse('getBalance')
        }
    }

    const resetBalance = async () => {
        dispatch({
            type: RESET_BALANCE,
        })
        dispatch({
            type: RESET_PAGINATION,
        })
    }

    const getSingleCharge = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleCharge', formData, '', 'account'),
            ])
            const from = 'getSingleCharge'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_CHARGE,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleTransfer = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleTransfer', formData, '', 'account'),
            ])
            const from = 'getSingleTransfer'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_TRANSFER,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllCharges = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getCharges', formData, '', 'account'),
            ])
            const from = 'getCharges'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_CHARGE,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllTransfers = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getTransfers', formData, '', 'account'),
            ])
            const from = 'getTransfers'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_TRANSFER,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const setSearchCharges = async (res) => {
        try {
            // dispatch({
            //     type: SET_PAYMENT_TAB_VALUE,
            //     payload: 3,
            // })
            dispatch({
                type: GET_ALL_CHARGE,
                payload: {
                    records: res.data.data.responseData.records.length
                        ? res.data.data.responseData.records
                        : [],
                },
            })
        } catch (err) {
            resp.commonErrorResponse('setSearchCharges')
        }
    }

    const getPayments = async (formData) => {
        try {
            dispatch({
                type: SET_LOADING_TRUE,
            })
            const [res] = await Promise.all([
                apiCall('post', 'getPayments', formData, '', 'account'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_CHARGE,
                    payload: {
                        records: res.data.data.records.records,
                        has_more: res.data.data.records.has_more,
                    },
                })
                if (formData.starting_after) {
                    dispatch({
                        type: SET_PAGINATION,
                        payload: {
                            pageCount: state.pagination.pageCount + 1,
                            previous: false,
                            next: !res.data.data.records.has_more,
                        },
                    })
                } else if (formData.ending_before) {
                    dispatch({
                        type: SET_PAGINATION,
                        payload: {
                            pageCount: state.pagination.pageCount - 1,
                            previous: state.pagination.pageCount <= 1 ? true : false,
                            next: false,
                        },
                    })
                } else {
                    dispatch({
                        type: SET_PAGINATION,
                        payload: {
                            next: !res.data.data.records.has_more,
                        },
                    })
                }
                dispatch({
                    type: SET_LOADING_FALSE,
                })
            } else {
                dispatch({
                    type: SET_LOADING_FALSE,
                })
                resp.commonResponse(res.data, 'getCharges')
            }
        } catch (err) {
            dispatch({
                type: SET_LOADING_FALSE,
            })
            resp.commonErrorResponse('getCharges')
        }
    }

    const resetCharges = () => {
        dispatch({
            type: RESET_CHARGES,
        })
        dispatch({
            type: RESET_PAGINATION,
        })
    }

    const getChargeById = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getChargeById', formData, '', 'account'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_CHARGE_DETAILS,
                    payload: res.data.data.responseData,
                })
            } else {
                resp.commonResponse(res.data, 'getCharges')
            }
        } catch (err) {
            resp.commonErrorResponse('getCharges')
        }
    }

    const getPayouts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getPayouts', formData, '', 'account'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_PAYOUT,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        hasMore: res.data.data.responseData.hasMore,
                    },
                })
                if (formData.starting_after) {
                    dispatch({
                        type: SET_PAGINATION,
                        payload: {
                            pageCount: state.pagination.pageCount + 1,
                            previous: false,
                            next: !res.data.data.responseData.hasMore,
                        },
                    })
                } else if (formData.ending_before) {
                    dispatch({
                        type: SET_PAGINATION,
                        payload: {
                            pageCount: state.pagination.pageCount - 1,
                            previous: state.pagination.pageCount <= 1 ? true : false,
                            next: false,
                        },
                    })
                } else {
                    dispatch({
                        type: SET_PAGINATION,
                        payload: {
                            next: !res.data.data.responseData.hasMore,
                        },
                    })
                }
            } else {
                resp.commonResponse(res.data, 'getPayouts')
            }
        } catch (err) {
            resp.commonErrorResponse('getPayouts')
        }
    }

    const resetPayout = () => {
        dispatch({
            type: RESET_PAYOUT,
        })
        dispatch({
            type: RESET_PAGINATION,
        })
    }

    const getPayoutById = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getPayoutById', formData, '', 'account'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_PAYOUT_DETAILS,
                    payload: res.data.data.responseData,
                })
            } else {
                resp.commonResponse(res.data, 'getCharges')
            }
        } catch (err) {
            resp.commonErrorResponse('getCharges')
        }
    }

    const getDisputes = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getDisputes', formData, '', 'account'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_DISPUTES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        hasMore: res.data.data.responseData.hasMore,
                    },
                })
            } else {
                resp.commonResponse(res.data, 'getDisputes')
            }
        } catch (err) {
            resp.commonErrorResponse('getDisputes')
        }
    }

    const getDisputeDetails = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getDisputeByID', formData, '', 'account'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_DISPUTE_DETAILS,
                    payload: res.data.data.responseData,
                })
            } else {
                resp.commonResponse(res.data, 'getDisputeDetails')
            }
        } catch (err) {
            resp.commonErrorResponse('getDisputeDetails')
        }
    }

    const closeDispute = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'closeDispute', formData, '', 'account'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: { status: res.data.status, message: res.data.data.message },
                })
            } else if (res.data.status === 'error') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('closeDispute')
        }
    }

    const updateDispute = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateDispute', formData, '', 'account'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: { status: res.data.status, message: res.data.data.message },
                })
            } else if (res.data.status === 'error') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('updateDispute')
        }
    }

    const getPaymentMethods = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'list', formData, '', 'source')])
            if (res.data.status === 'success') {
            } else {
                resp.commonResponse(res.data, 'getPaymentMethods')
            }
        } catch (err) {
            resp.commonErrorResponse('getPaymentMethods')
        }
    }

    const initiateRefund = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'createRefund', formData, '', 'account'),
            ])
            if (res.data.status === 'success') {
                console.log('in here')
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: { status: res.data.status, message: res.data.data.message },
                })
            } else if (res.data.status === 'error') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res.data.status,
                        message: 'Refund Unsuccessful. Please contact admin for further details.',
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('initiateRefunt')
        }
    }

    const listAllAccount = async () => {
        try {
            const [res] = await Promise.all([apiCall('post', 'listAllAccount', '', '', 'account')])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_ACCOUNTS,
                    payload: res.data.data.responseData.data,
                })
            }
        } catch (err) {
            resp.commonErrorResponse('listAllAccount')
        }
    }

    const listAccountSource = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'retriveAccountDetails', formData, '', 'auth'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_ACCOUNT_SOURCE,
                    payload: res.data.data.responseData,
                })
            }
        } catch (err) {
            resp.commonErrorResponse('listAccountSource')
        }
    }

    const clearAccountSource = async () => {
        dispatch({
            type: CLEAR_ACCOUNT_SOURCE,
        })
    }

    const pageAdd = () => {
        dispatch({
            type: PAGE_COUNT_ADD,
        })
    }

    const pageSub = () => {
        dispatch({
            type: PAGE_COUNT_SUB,
        })
    }

    const pageReset = () => {
        dispatch({
            type: PAGE_COUNT_RESET,
        })
    }

    const setChargeId = (value) => {
        dispatch({
            type: SET_CHARGE_ID,
            payload: value,
        })
    }

    const setCustomerId = (customerId) => {
        dispatch({
            type: SET_CUSTOMER_ID,
            payload: customerId,
        })
    }

    const setCustomerDetails = (customerDetails) => {
        dispatch({
            type: SET_CUSTOMER_DETAILS,
            payload: customerDetails,
        })
    }

    const setPayoutId = (payoutId) => {
        dispatch({
            type: SET_PAYOUT_ID,
            payload: { payoutId },
        })
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const setPagination = (value) => {
        dispatch({
            type: SET_PAGINATION,
            payload: value,
        })
    }

    const resetPagination = () => {
        dispatch({
            type: RESET_PAGINATION,
        })
    }

    const setPaymentTabValue = (value) => {
        dispatch({
            type: SET_PAYMENT_TAB_VALUE,
            payload: value,
        })
    }

    return (
        <AccountContext.Provider
            value={{
                responseStatus: state.responseStatus,
                accountBalance: state.accountBalance,
                all_customers: state.all_customers,
                all_balances: state.all_balances,
                all_charges: state.all_charges,
                all_transfers: state.all_transfers,
                all_payouts: state.all_payouts,
                all_disputes: state.all_disputes,
                disputeDetails: state.disputeDetails,
                paginationPage: state.paginationPage,
                chargeId: state.chargeId,
                chargeDetails: state.chargeDetails,
                customerId: state.customerId,
                customerDetails: state.customerDetails,
                payoutId: state.payoutId,
                payoutDetails: state.payoutDetails,
                accounts: state.accounts,
                account_source: state.account_source,
                pagination: state.pagination,
                paymentTabValue: state.paymentTabValue,
                single_charge: state.single_charge,
                single_customer: state.single_customer,
                loading: state.loading,
                single_transfer: state.single_transfer,
                clearResponse,
                createExternalAccount,
                getDashboardBalance,
                getDashboardBalanceWithRefund,
                getBalance,
                resetBalance,
                getDisputes,
                getDisputeDetails,
                closeDispute,
                updateDispute,
                getPayouts,
                resetPayout,
                getCustomer,
                setSearchCustomer,
                getAllCharges,
                setSearchCharges,
                getPayments,
                resetCharges,
                pageAdd,
                pageSub,
                pageReset,
                setChargeId,
                getChargeById,
                setCustomerId,
                getCustomerById,
                setCustomerDetails,
                setPayoutId,
                getPayoutById,
                getPaymentMethods,
                initiateRefund,
                listAllAccount,
                listAccountSource,
                clearAccountSource,
                setPagination,
                resetPagination,
                setPaymentTabValue,
                getSingleCharge,
                getSingleCustomer,
                getAllTransfers,
                getSingleTransfer,
            }}
        >
            {props.children}
        </AccountContext.Provider>
    )
}

export default AuctionState
