import React from 'react'
import './App.css'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import AuthState from './context/auth/authState'
import CommonState from './context/common/commonState'
import UserState from './context/user/userState'
import AlertState from './context/alert/alertState'
import ProductState from './context/product/productState'
import AccountState from './context/account/accountState'
import CustomerState from './context/customer/customerState'
import CartState from './context/cart/cartState'
import BuyerState from './context/buyer/buyerState'
import AutopltState from './context/autopilot/autopltState'
import PayState from './context/payment/payState'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import Alerts from './common/alert'
import { setAuthToken, setIPAddress } from './common/api'
import ReportState from './context/Report/reportState'
import SettingsState from './context/settings/settingsState'
import Splitpayment from './context/splitpayment/splitpaymentState'
import SearchState from './context/search/searchState'
import AdminState from './context/admin/adminState'
import TwofactorState from './context/admin/twoFactor/twofactorState'
import AdminAuthState from './context/admin/auth/authState'
import AdminTemplateState from './context/admin/template/templateState'
import EmployeeState from './context/employee/employeeState'

import HeadAlert from './routes/headAlert'

global.site_url = process.env.REACT_APP_DOMAIN
global.images_url = global.site_url + '/uploads/product/'
global.ignoreStates = []
global.ignoreCountry = []
global.defaultCountry = 'US'

if (localStorage.token) {
    setAuthToken(localStorage.token)
}

const App = () => {
    return (
        <CookiesProvider>
            <CommonState>
                <EmployeeState>
                    <AdminTemplateState>
                        <AuthState>
                            <AlertState>
                                <AccountState>
                                    <CustomerState>
                                        <UserState>
                                            <PayState>
                                                <AutopltState>
                                                    <ReportState>
                                                        <SettingsState>
                                                            <Splitpayment>
                                                                <AdminState>
                                                                    <TwofactorState>
                                                                        <SearchState>
                                                                            <AdminAuthState>
                                                                                <MuiPickersUtilsProvider
                                                                                    utils={
                                                                                        MomentUtils
                                                                                    }
                                                                                >
                                                                                    <SnackbarProvider
                                                                                        maxSnack={3}
                                                                                    >
                                                                                        <div className="App">
                                                                                            <HeadAlert />
                                                                                            <Alerts />
                                                                                            <Router>
                                                                                                <Routes />
                                                                                            </Router>
                                                                                        </div>
                                                                                    </SnackbarProvider>
                                                                                </MuiPickersUtilsProvider>
                                                                            </AdminAuthState>
                                                                        </SearchState>
                                                                    </TwofactorState>
                                                                </AdminState>
                                                            </Splitpayment>
                                                        </SettingsState>
                                                    </ReportState>
                                                </AutopltState>
                                            </PayState>
                                        </UserState>
                                    </CustomerState>
                                </AccountState>
                            </AlertState>
                        </AuthState>
                    </AdminTemplateState>
                </EmployeeState>
            </CommonState>
        </CookiesProvider>
    )
}

export default App
