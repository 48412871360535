import { GET_TWO_FACTOR_REQUEST, RESPONSE_STATUS, CLEAR_RESPONSE } from './twofactorTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_TWO_FACTOR_REQUEST:
            return {
                ...state,
                request_data: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
