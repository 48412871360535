import React, { useState, useContext } from 'react'
import { Button, ListItem, Menu, MenuItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import PrimaryButton from '../../atoms/PrimaryButton'
import CustomInput from '../../atoms/Inputs/CustomInput'
import AuthContext from '../../../context/auth/authContext'
import SearchContext from '../../../context/search/searchContext'
import { handleRedirectInternal } from '../../../common/components'
import './DashboardTopPanel.css'
import CreateTransaction from '../../../views/CreateTransaction'

function DashboardTopPanel() {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [anchorHelp, setAnchorHelp] = React.useState(null)
    const [searchString, setSearchString] = React.useState(null)

    const authContext = useContext(AuthContext)
    const searchContext = useContext(SearchContext)

    const { user, isAuthenticated, isAdmin, logout } = authContext
    const { getSearchData } = searchContext

    const history = useHistory()

    const [open, setOpen] = React.useState(false)

    const handleInvoiceClose = () => {
        setOpen(false)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (history, path) => {
        setAnchorEl(null)
    }

    const handleHelpClick = (event) => {
        setAnchorHelp(event.currentTarget)
    }
    const handleHelpClose = () => {
        setAnchorHelp(null)
    }

    const onLogOut = () => {
        setAnchorEl(null)
        setAnchorHelp(null)
        localStorage.removeItem('admintoken')
        logout()
        handleRedirectInternal(history, '')
    }

    const handleCreateTransaction = () => {
        setOpen(true)
    }

    const handleChange = (e) => {
        setSearchString(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        history.location.search = searchString
        getSearchData(history.location)
    }

    return (
        <div className="dashboardTopPanel d-flex justify-content-between align-items-center">
            <CreateTransaction open={open} handleClose={handleInvoiceClose} />
            <div className="topPanelSearch">
                <form onSubmit={handleSubmit}>
                    <CustomInput label="Search" onChange={handleChange} borderType="none" />
                </form>
            </div>
            <div className="topPanelLinks d-flex align-items-center">
                {/* <div className="mr-3">
                    <PrimaryButton label="Create Transaction" onClick={handleCreateTransaction} />
                </div> */}
                <Button className="button">Feedback?</Button>
                <Button className="button">
                    <span className="material-icons">notifications</span>
                </Button>
                <div>
                    <Button className="button" onClick={handleHelpClick}>
                        <span className="material-icons">help</span>
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorHelp}
                        keepMounted
                        open={Boolean(anchorHelp)}
                        onClose={handleHelpClose}
                    >
                        <MenuItem onClick={() => handleRedirectInternal(history, 'declinecodes')}>
                            Decline Codes
                        </MenuItem>
                    </Menu>
                </div>
                <div>
                    <Button
                        className="button"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <span className="material-icons">account_circle</span>
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => handleRedirectInternal(history, 'profile')}>
                            Profile
                        </MenuItem>
                        <MenuItem onClick={() => onLogOut()}>Logout</MenuItem>
                    </Menu>
                </div>
            </div>
        </div>
    )
}

export default DashboardTopPanel
