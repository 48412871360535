import {
    SET_RESPONSE_STATUS,
    CLEAR_RESPONSE,
    SET_BUSINESS_DETAILS,
    SET_AUTH_URI,
    CLEAR_AUTH_URI,
    SET_QUICKBOOKS_TOKEN,
    CLEAR_QUICKBOOKS_TOKEN,
    SET_VIRTUAL_CARDS,
} from './settingsTypes'

export default (state, action) => {
    switch (action.type) {
        case SET_RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case SET_BUSINESS_DETAILS:
            return {
                ...state,
                businessDetails: action.payload,
            }
        case SET_AUTH_URI:
            return {
                ...state,
                authUri: action.payload,
            }
        case CLEAR_AUTH_URI:
            return {
                ...state,
                authUri: null,
            }
        case SET_QUICKBOOKS_TOKEN:
            // localStorage.quickbooksToken = JSON.stringify(action.payload)
            localStorage.setItem('quickbooksToken', JSON.stringify(action.payload))
            return {
                ...state,
                quickbooksToken: action.payload,
            }
        case CLEAR_QUICKBOOKS_TOKEN:
            localStorage.removeItem('quickbooksToken')
            return {
                ...state,
                quickbooksToken: null,
            }
        case SET_VIRTUAL_CARDS:
            return {
                ...state,
                virtualCards: action.payload,
            }
        default:
            return state
    }
}
