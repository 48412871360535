import React from 'react'
import './invoice.css'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import Invoice from './Invoice'
import Accordion from './Accordion'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        color: '#000',
        backgroundColor: '#fff',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}

export default function CreateTransaction(props) {
    const classes = useStyles()

    const formik = useFormik({
        initialValues: {
            payment_type: 0,
            items: [],
            date: Date.now(),
        },
        // validationSchema: validationArray,
        onSubmit: (values) => {
            console.log(values)
        },
    })

    const handlePaymentType = (event, newValue) => {
        formik.setFieldValue('payment_type', newValue)
    }

    return (
        <Dialog
            fullScreen
            open={props.open}
            onClose={props.handleClose}
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBar} elevation={0}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.handleClose}
                        aria-label="close"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        CREATE TRANSACTION
                    </Typography>
                    <PrimaryButton label="Share Invoice" onClick={props.handleClose} />
                </Toolbar>
            </AppBar>
            <div className="body">
                <div className="btn_group_tablist">
                    {[
                        { id: 'payment_type', label: 'Payment Type' },
                        { id: 'buyer_vendor', label: 'Buyer & Vendor' },
                        { id: 'items', label: 'Items' },
                        { id: 'payment_terms', label: 'Payment Terms' },
                        { id: 'advanced', label: 'Advanced' },
                        { id: 'notes', label: 'Notes' },
                    ].map((value, index) => (
                        <a href={`#${value.id}`}>
                            <Button>{value.label}</Button>
                        </a>
                    ))}
                </div>
                <Accordion id="payment_type" title="Payment Type">
                    <div>
                        <Tabs
                            className="tabs"
                            TabIndicatorProps={{
                                style: { opacity: '0' },
                            }}
                            value={formik.values.payment_type}
                            onChange={handlePaymentType}
                        >
                            {['Invoice', 'Recurring', 'MileStones', 'Installments'].map(
                                (value, index) => (
                                    <Tab label={value} {...a11yProps(index)} />
                                ),
                            )}
                        </Tabs>
                        <Typography className="text">
                            Send your customers a live payment invoice.
                        </Typography>
                    </div>
                </Accordion>
                <TabPanel className="tabpanel" value={formik.values.payment_type} index={0}>
                    <Invoice formik={formik} />
                </TabPanel>
                <TabPanel
                    className="tabpanel"
                    value={formik.values.payment_type}
                    index={1}
                ></TabPanel>
                <TabPanel
                    className="tabpanel"
                    value={formik.values.payment_type}
                    index={2}
                ></TabPanel>
                <TabPanel
                    className="tabpanel"
                    value={formik.values.payment_type}
                    index={3}
                ></TabPanel>
            </div>
        </Dialog>
    )
}
