import React, { useReducer } from 'react'
import UserContext from './userContext'
import UserReducer from './userReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { GET_STATIC_PAGE, CLEAR_RESPONSE, RESPONSE_STATUS } from './userTypes'

const UserState = (props) => {
    const initialState = {
        responseStatus: null,
        static_page: {},
    }

    const [state, dispatch] = useReducer(UserReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const updateProfile = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateProfile', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'updateProfile')
        } catch (err) {
            resp.commonErrorResponse('updateProfile')
        }
    }

    const forgotPassword = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'forgotPassword', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'forgotPassword')
        } catch (err) {
            resp.commonErrorResponse('forgotPassword')
        }
    }

    const sendPhoneVerifyCode = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'sendPhoneVerifyCode', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'sendPhoneVerifyCode')
        } catch (err) {
            resp.commonErrorResponse('sendPhoneVerifyCode')
        }
    }

    const verifyPhoneVerifyCode = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'verifyPhoneVerifyCode', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'verifyPhoneVerifyCode')
        } catch (err) {
            resp.commonErrorResponse('verifyPhoneVerifyCode')
        }
    }

    const resetPassword = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'resetPassword', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'resetPassword')
        } catch (err) {
            resp.commonErrorResponse('resetPassword')
        }
    }

    const verifyEmail = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'verifyEmail', formData, '', 'user')])
            resp.commonResponse(res.data, 'verifyEmail')
        } catch (err) {
            resp.commonErrorResponse('verifyEmail')
        }
    }

    const getStaticPage = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'show_static_content', formData)])
            if (res.data.status === 'true') {
                dispatch({
                    type: GET_STATIC_PAGE,
                    payload: res.data.data[0],
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <UserContext.Provider
            value={{
                responseStatus: state.responseStatus,
                static_page: state.static_page,
                clearResponse,
                sendPhoneVerifyCode,
                verifyPhoneVerifyCode,
                updateProfile,
                forgotPassword,
                resetPassword,
                verifyEmail,
                getStaticPage,
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}

export default UserState
