import React from 'react'
import AdminSidePanel from '../../organisms/AdminSidePanel'
import AdminTopPanel from '../../organisms/AdminTopPanel'
import './AdminLayout.css'

function AdminLayout(props) {
    return (
        <div className="adminLayout admin">
            <div className="dashboardLayoutLt">
                <AdminSidePanel />
            </div>
            <div className="dashboardLayoutRt">
                <AdminTopPanel />
                <div className="dashboardChild">{props.children}</div>
            </div>
        </div>
    )
}

export default AdminLayout
