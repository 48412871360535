import {
    GET_ALL_REPORT_DISPLAY,
    SET_REPORT,
    SET_REPORT_SUMMARY,
    SET_REPORT_DETAILS,
    SET_PAYMENT_SUMMARY,
    SET_PAYOUT_REPORT,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './reportTypes'

export default (state, action) => {
    switch (action.type) {
        case SET_REPORT:
            return {
                ...state,
                reportType: action.payload,
            }
        case SET_REPORT_SUMMARY:
            return {
                ...state,
                reportSummary: action.payload,
            }
        case SET_REPORT_DETAILS:
            return {
                ...state,
                reportDetails: action.payload,
            }
        case GET_ALL_REPORT_DISPLAY:
            return {
                ...state,
                reports_data: action.payload,
            }
        case SET_PAYMENT_SUMMARY:
            return {
                ...state,
                paymentSummary: action.payload,
            }
        case SET_PAYOUT_REPORT:
            return {
                ...state,
                payoutReport: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
