import React from 'react'
import TextField from '@material-ui/core/TextField'
import { FormControl, InputLabel, Select } from '@material-ui/core'

const CustomSelect = (props) => {
    const shrink = props.shrink ? props.shrink.toString() : 'false'
    return (
        <div className={`customSelect ${props.className}`}>
            {props.selectType === 'noBorder' ? (
                <FormControl>
                    <InputLabel id={props.id}>{props.label}</InputLabel>
                    <Select
                        id={props.id}
                        size={props.size}
                        label={props.label}
                        SelectProps={{
                            native: true,
                        }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={props.disabled}
                        name={props.name}
                        value={props.value}
                        autoFocus={props.autoFocus}
                        onChange={props.onChange || props.onChange}
                        error={props.error}
                        helperText={props.helperText}
                    >
                        {props.children}
                    </Select>
                </FormControl>
            ) : (
                <TextField
                    id={props.id}
                    select
                    size={props.size}
                    label={props.label}
                    SelectProps={{
                        native: true,
                    }}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: shrink,
                    }}
                    disabled={props.disabled}
                    name={props.name}
                    variant="outlined"
                    value={props.value}
                    autoFocus={props.autoFocus}
                    onChange={props.onChange || props.onChange}
                    error={props.error}
                    helperText={props.helperText}
                >
                    {props.children}
                </TextField>
            )}
        </div>
    )
}

export default CustomSelect
