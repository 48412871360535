export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'

export const GET_ACCOUNT_BALANCE = 'GET_ACCOUNT_BALANCE'
export const GET_ACCOUNT_CUSTOMER = 'GET_ACCOUNT_CUSTOMER'

export const GET_ALL_BALANCE = 'GET_ALL_BALANCE'
export const GET_ALL_CHARGE = 'GET_ALL_CHARGE'
export const GET_ALL_PAYOUT = 'GET_ALL_PAYOUT'
export const GET_ALL_TRANSFER = 'GET_ALL_TRANSFER'

export const GET_ALL_DISPUTES = 'GET_ALL_DISPUTES'
export const GET_DISPUTE_DETAILS = 'GET_DISPUTE_DETAILS'

export const PAGE_COUNT_ADD = 'PAGE_COUNT_ADD'
export const PAGE_COUNT_SUB = 'PAGE_COUNT_SUB'
export const PAGE_COUNT_RESET = 'PAGE_COUNT_RESET'

export const SET_CHARGE_ID = 'SET_CHARGE_ID'
export const SET_CHARGE_DETAILS = 'SET_CHARGE_DETAILS'

export const SET_CUSTOMER_ID = 'SET_CUSTOMER_ID'
export const SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS'

export const SET_PAYOUT_ID = 'SET_PAYOUT_ID'
export const SET_PAYOUT_DETAILS = 'SET_PAYOUT_DETAILS'
export const SET_ACCOUNTS = 'SET_ACCOUNTS'
export const SET_ACCOUNT_SOURCE = 'SET_ACCOUNT_SOURCE'
export const CLEAR_ACCOUNT_SOURCE = 'CLEAR_ACCOUNT_SOURCE'

export const SET_PAGINATION = 'SET_PAGINATION'
export const RESET_PAGINATION = 'RESET_PAGINATION'
export const RESET_CHARGES = 'RESET_CHARGES'
export const SET_PAYMENT_TAB_VALUE = 'SET_PAYMENT_TAB_VALUE'
export const RESET_PAYOUT = 'RESET_PAYOUT'
export const RESET_BALANCE = 'RESET_BALANCE'

export const SET_LOADING_TRUE = 'SET_LOADING_TRUE'
export const SET_LOADING_FALSE = 'SET_LOADING_FALSE'
export const GET_SINGLE_CHARGE = 'GET_SINGLE_CHARGE'
export const GET_SINGLE_CUSTOMER = 'GET_SINGLE_CUSTOMER'
export const GET_SINGLE_TRANSFER = 'GET_SINGLE_TRANSFER'
