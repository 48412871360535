import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_ACCOUNTS,
    GET_ALL_PG_ACCOUNTS,
    GET_ALL_SELLERS,
    SET_RESPONSE,
    GET_REVENUE_CHARGES,
    GET_REVENUE_CHARGE_FEES,
    GET_SINGLE_ACCOUNT,
} from './adminTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case GET_ALL_ACCOUNTS:
            return {
                ...state,
                all_accounts: action.payload,
            }
        case GET_ALL_PG_ACCOUNTS:
            return {
                ...state,
                all_pg_accounts: action.payload,
            }
        case GET_ALL_SELLERS:
            return {
                ...state,
                all_sellers: action.payload,
            }
        case GET_REVENUE_CHARGES:
            return {
                ...state,
                all_revenue_charges: action.payload,
            }
        case GET_REVENUE_CHARGE_FEES:
            return {
                ...state,
                all_revenue_charge_fees: action.payload,
            }
        case GET_SINGLE_ACCOUNT:
            return {
                ...state,
                single_account: action.payload,
            }
        case SET_RESPONSE:
            return {
                ...state,
                responseStatus: action.payload,
            }
        default:
            return state
    }
}
