import React, { useReducer } from 'react'
import TwofactorContext from './twofactorContext'
import TwofactorReducer from './twofactorReducer'
import { apiCall } from '../../../common/api'
import { response } from '../../common'
import { GET_TWO_FACTOR_REQUEST, RESPONSE_STATUS, CLEAR_RESPONSE } from './twofactorTypes'

const TwofactorState = (props) => {
    const initialState = {
        request_data: {
            base64Data: null,
            secret: {},
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(TwofactorReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const get2faRequest = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'request2FA', formData, '', 'admin/twoFactor'),
            ])
            const from = 'Invoices'
            if (res.data.status === 'success') {
                console.log(res.data, 'twofactorrequest')
                dispatch({
                    type: GET_TWO_FACTOR_REQUEST,
                    payload: {
                        base64Data: res.data.data.responseData.base64Data,
                        secret: res.data.data.responseData.secret,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const verify2fa = async (formData, fromData) => {
        try {
            const from = fromData ? fromData : 'verify'
            const [res] = await Promise.all([
                apiCall('post', 'verify2FA', formData, '', 'admin/twoFactor'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const revoke2fa = async (formData) => {
        try {
            const from = 'verify'
            const [res] = await Promise.all([
                apiCall('post', 'revoke2FA', formData, '', 'admin/twoFactor'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <TwofactorContext.Provider
            value={{
                request_data: state.request_data,
                responseStatus: state.responseStatus,
                verify2fa,
                revoke2fa,
                clearResponse,
                get2faRequest,
            }}
        >
            {props.children}
        </TwofactorContext.Provider>
    )
}

export default TwofactorState
