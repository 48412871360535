import React, { useEffect, useContext } from 'react'
import AuthContext from '../context/auth/authContext'
import CommonContext from '../context/common/commonContext'
import AlertContext from '../context/alert/alertContext'
import AccountContext from '../context/account/accountContext'
import CustomerContext from '../context/customer/customerContext'
import SettingsContext from '../context/settings/settingsContext'
import EmployeeContext from '../context/employee/employeeContext'
import SplitPaymentContext from '../context/splitpayment/splitpaymentContext'
import AdminContext from '../context/admin/adminContext'
import UserContext from '../context/user/userContext'
import AdminAuthContext from '../context/admin/auth/authContext'
import ReportContext from '../context/Report/reportContext'

const HeadAlert = () => {
    const authContext = useContext(AuthContext)
    const accountContext = useContext(AccountContext)
    const userContext = useContext(UserContext)
    const customerContext = useContext(CustomerContext)
    const settingsContext = useContext(SettingsContext)
    const employeeContext = useContext(EmployeeContext)
    const splitPaymentContext = useContext(SplitPaymentContext)
    const commonContext = useContext(CommonContext)
    const adminAuthContext = useContext(AdminAuthContext)
    const reportContext = useContext(ReportContext)

    const adminContext = useContext(AdminContext)

    const { responseStatus: responseStatusAuth, clearResponse: clearResponseAuth } = authContext
    const {
        responseStatus: responseStatusAdminAuth,
        clearResponse: clearResponseAdminAuth,
    } = adminAuthContext

    const {
        responseStatus: responseStatusAccount,
        clearResponse: clearResponseAccount,
    } = accountContext

    const { responseStatus: responseStatusUser, clearResponse: clearResponseUser } = userContext

    const {
        responseStatus: responseStatusCustomer,
        clearResponse: clearResponseCustomer,
    } = customerContext

    const {
        responseStatus: responseStatusSettings,
        clearResponse: clearResponseSettings,
    } = settingsContext

    const {
        responseStatus: responseStatusSplitpayment,
        clearResponse: clearResponseSplitpayment,
    } = splitPaymentContext

    const {
        responseStatus: responseStatusCommon,
        clearResponse: clearResponseCommon,
    } = commonContext

    const {
        responseStatus: responseStatusEmployee,
        clearResponse: clearResponseEmployee,
    } = employeeContext

    const { responseStatus: responseStatusAdmin, clearResponse: clearResponseAdmin } = adminContext

    const {
        responseStatus: responseStatusReport,
        clearResponse: clearResponseReport,
    } = reportContext

    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext

    useEffect(() => {
        if (responseStatusAuth) {
            setAlert(responseStatusAuth.message, responseStatusAuth.status)
            clearResponseAuth()
        }
        if (responseStatusAccount) {
            setAlert(responseStatusAccount.message, responseStatusAccount.status)
            clearResponseAccount()
        }
        if (responseStatusUser) {
            setAlert(responseStatusUser.message, responseStatusUser.status)
            clearResponseUser()
        }
        if (responseStatusCustomer) {
            setAlert(responseStatusCustomer.message, responseStatusCustomer.status)
            clearResponseCustomer()
        }
        if (responseStatusSettings) {
            setAlert(responseStatusSettings.message, responseStatusSettings.status)
            clearResponseSettings()
        }
        if (responseStatusEmployee) {
            setAlert(responseStatusEmployee.message, responseStatusEmployee.status)
            clearResponseEmployee()
        }
        if (responseStatusSplitpayment) {
            setAlert(responseStatusSplitpayment.message, responseStatusSplitpayment.status)
            clearResponseSplitpayment()
        }
        if (responseStatusCommon) {
            setAlert(responseStatusCommon.message, responseStatusCommon.status)
            clearResponseCommon()
        }
        if (responseStatusAdmin) {
            setAlert(responseStatusAdmin.message, responseStatusAdmin.status)
            clearResponseAdmin()
        }
        if (responseStatusAdminAuth) {
            setAlert(responseStatusAdminAuth.message, responseStatusAdminAuth.status)
            clearResponseAdminAuth()
        }
        if (responseStatusReport) {
            setAlert(responseStatusReport.message, responseStatusReport.status)
            clearResponseAdminAuth()
        }
    }, [
        responseStatusAuth,
        responseStatusAccount,
        responseStatusUser,
        responseStatusCustomer,
        responseStatusSettings,
        responseStatusSplitpayment,
        responseStatusCommon,
        responseStatusAdmin,
        responseStatusAdminAuth,
        responseStatusEmployee,
        responseStatusReport,
    ])

    return <></>
}
export default HeadAlert
