export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const SET_INVOICE_LIST = 'SET_INVOICE_LIST'
export const RESET_INVOICE_LIST = 'RESET_INVOICE_LIST'
export const SET_SUBSCRIPTION_LIST = 'SET_SUBSCRIPTION_LIST'
export const RESET_SUBSCRIPTION_LIST = 'RESET_SUBSCRIPTION_LIST'
export const SET_NEW_CUSTOMER = 'SET_NEW_CUSTOMER'
export const SET_PAGINATION = 'SET_PAGINATION'
export const RESET_PAGINATION = 'RESET_PAGINATION'
export const SET_CUSTOMER_SOURCE = 'SET_CUSTOMER_SOURCE'
export const SET_CUSTOMER_BY_ID = 'SET_CUSTOMER_BY_ID'
export const GET_ALL_CUSTOMER = 'GET_ALL_CUSTOMER'
export const SET_INVOICE_ITEMS = 'SET_INVOICE_ITEMS'
export const SET_TAX_COUPONS = 'SET_TAX_COUPONS'
export const SET_LOADING_TRUE = 'SET_LOADING_TRUE'
export const SET_LOADING_FALSE = 'SET_LOADING_FALSE'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const SET_RESPONSE = 'SET_RESPONSE'
export const GET_ACCOUNT_CUSTOMER = 'GET_ACCOUNT_CUSTOMER'
export const SET_CUSTOMIZE_INVOICE = 'SET_CUSTOMIZE_INVOICE'
