import React, { useReducer, useEffect } from 'react'
import AuthContext from './authContext'
import AuthReducer from './authReducer'
import { apiCall, setAuthToken } from '../../../common/api'
import { response } from '../../common'
import {
    USER_LOADED,
    LOGOUT,
    RESPONSE_STATUS,
    SET_COMPANY_NAME,
    CLEAR_RESPONSE,
    GET_2FA_USER_DETAILS,
} from './authTypes'

const AuthState = (props) => {
    const initialState = {
        token: localStorage.getItem('admintoken'),
        isAuthenticated: null,
        isAdmin: null,
        loading: true,
        user: {},
        twoFactor_details: {
            record: [],
        },
        responseStatus: null,
    }

    useEffect(() => {
        console.log('Coming here')
        if (localStorage.admintoken) {
            loadUser()
        }
    }, [])

    const [state, dispatch] = useReducer(AuthReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    // Check Email User
    const checkValidation = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkValidation', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'checkValidation')
        } catch (err) {
            resp.commonErrorResponse('checkValidation')
        }
    }

    // Login User
    const login = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'login', formData, '', 'admin')])
            resp.commonResponse(res.data, 'login')
            if (res.data.status === 'success') {
                console.log('Token Setting', res.data.data)
                localStorage.setItem('admintoken', res.data.data.token)
                console.log('Token Setting 222')
                loadUser()
            }
        } catch (err) {
            resp.commonErrorResponse('login')
        }
    }

    // Load User
    const loadUser = async () => {
        console.log('localStorage.admintoken 11', localStorage.admintoken)
        if (localStorage.admintoken) {
            setAuthToken(localStorage.admintoken)
        }
        console.log('localStorage.admintoken', localStorage.admintoken)
        const [res] = await Promise.all([apiCall('post', 'validateUser', {}, '', 'admin')])
        if (res.data.status === 'success') {
            dispatch({
                type: USER_LOADED,
                payload: {
                    data: res.data.data.responseData,
                },
            })
            // retrieveAccount(res.data.data.responseData.account_id)
        } else if (res.data.status === 'error') {
            setAuthToken()
            dispatch({
                type: LOGOUT,
            })
        } else {
            setAuthToken()
            dispatch({
                type: LOGOUT,
            })
        }
    }

    // const retrieveAccount = async (account_id) => {
    //     const [res] = await Promise.all([
    //         apiCall('post', 'retriveAccountDetails', { account_id }, '', 'admin'),
    //     ])
    //     if (
    //         res.data.status === 'success' &&
    //         res?.data?.data?.responseData?.business_profile?.name
    //     ) {
    //         dispatch({
    //             type: SET_COMPANY_NAME,
    //             payload: {
    //                 data: res.data.data.responseData.business_profile.name,
    //             },
    //         })
    //     }
    // }

    const get2faDetails = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'get2faDetails', formData, '', 'admin'),
            ])
            const from = '2faDetails'
            if (res.data.status === 'success') {
                console.log(res.data.data.responseData, 'restttt')
                dispatch({
                    type: GET_2FA_USER_DETAILS,
                    payload: {
                        record: res.data.data.responseData.length ? res.data.data.responseData : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('2fatoken')
        }
    }

    // Log out
    const logout = () => {
        setAuthToken()
        dispatch({
            type: RESPONSE_STATUS,
            payload: {
                status: 'success',
                message: 'Logged out successfully',
            },
        })
        dispatch({
            type: LOGOUT,
        })
    }

    // Clear Response
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                isAuthenticated: state.isAuthenticated,
                isAdmin: state.isAdmin,
                loading: state.loading,
                user: state.user,
                responseStatus: state.responseStatus,
                twoFactor_details: state.twoFactor_details,
                checkValidation,
                get2faDetails,
                login,
                logout,
                loadUser,
                clearResponse,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState
