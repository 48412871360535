import React, { useReducer } from 'react'
import ReportContext from './reportContext'
import ReportReducer from './reportReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'
import axios from 'axios'

import {
    GET_ALL_REPORT_DISPLAY,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    SET_REPORT,
    SET_REPORT_SUMMARY,
    SET_REPORT_DETAILS,
    SET_PAYMENT_SUMMARY,
    SET_PAYOUT_REPORT,
} from './reportTypes'
import cmd from 'node-cmd'
const FileSaver = require('file-saver')

const ReportState = (props) => {
    const initialState = {
        responseStatus: null,
        reportType: null,
        reportSummary: null,
        reports_data: null,
        reportDetails: null,
        paymentSummary: null,
        payoutReport: null,
    }
    const [state, dispatch] = useReducer(ReportReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getReportTypes = async () => {
        try {
            const [res] = await Promise.all([apiCall('post', 'reportType', '', '', 'reports')])
            if (res.data.status === 'success') {
                let formData = {
                    interval_start: res.data.data.responseData.data_available_start,
                    interval_end: res.data.data.responseData.data_available_end,
                }
                const [reportRunRes] = await Promise.all([
                    apiCall('post', 'reportRun', formData, '', 'reports'),
                ])
                if (reportRunRes.data.status === 'success') {
                    if (reportRunRes.data.data.responseData.status === 'pending') {
                        let formData = {
                            report_id: reportRunRes.data.data.responseData.id,
                        }
                        let reportResultRes
                        const reportResult = async () => {
                            ;[reportResultRes] = await Promise.all([
                                apiCall('post', 'retrieveReport', formData, '', 'reports'),
                            ])
                            if (reportResultRes.data.data.responseData.result === null) {
                                setTimeout(() => reportResult(), 3000)
                            } else {
                                dispatch({
                                    type: SET_REPORT,
                                    payload: reportResultRes.data.data.responseData,
                                })
                            }
                        }
                        reportResult()
                    }
                }
            }
        } catch (err) {
            resp.commonErrorResponse('getCustomersSource')
        }
    }

    const getReportSummary = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'reportSummary', formData, '', 'reports'),
            ])
            dispatch({
                type: SET_REPORT_SUMMARY,
                payload: res.data.data.data,
            })
        } catch (err) {
            resp.commonErrorResponse('getReportSummary')
        }
    }

    const getPaymentsSummary = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getPaymentsSummary', formData, '', 'reports'),
            ])
            dispatch({
                type: SET_PAYMENT_SUMMARY,
                payload: res.data.data.responseData,
            })
        } catch (err) {
            resp.commonErrorResponse('getReportSummary')
        }
    }

    const getAllReportDisplay = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'display', formData, '', 'reports')])
            const from = 'ReportDisplay'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_REPORT_DISPLAY,
                    payload: res.data.data.responseData,
                })
                // resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllReportDownload = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'download', formData, '', 'reports')])
            const from = 'ReportDisplay'
            if (res.data.status === 'success') {
                const csvData = new Blob([res.data.data.responseData.records], {
                    type: 'text/csv;charset=utf-8;',
                })
                FileSaver.saveAs(csvData, res.data.data.responseData.filename)
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllExportDownload = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'export', formData, '', 'reports')])
            const from = 'ReportDisplay'
            if (res.data.status === 'success') {
                const csvData = new Blob([res.data.data.responseData.records], {
                    type: 'text/csv;charset=utf-8;',
                })
                FileSaver.saveAs(csvData, res.data.data.responseData.filename)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const sendPayoutReportRequest = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'reportRun', formData, '', 'reports')])
            let from = 'sendPayoutReportRequest'
            if (res.data.status === 'success' && res.data.data.linkData) {
                dispatch({
                    type: SET_PAYOUT_REPORT,
                    payload: res.data.data.linkData,
                })
                // resp.commonResponse(res.data, from)
            } else if (res.data.status === 'success') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('sendPayoutReportRequest')
        }
    }

    const downloadStripeReport = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'downloadStripeReport', formData, '', 'reports'),
            ])

            if (res.data.status === 'success' && res.data.data.linkData) {
                dispatch({
                    type: SET_PAYOUT_REPORT,
                    payload: res.data.data.linkData,
                })
                // resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse('downloadStripeReport')
            }
        } catch (err) {
            resp.commonErrorResponse('downloadStripeReport')
        }
    }

    const resetReport = async () => {
        dispatch({
            type: SET_PAYOUT_REPORT,
            payload: null,
        })
    }

    const clearResponse = async () => {
        dispatch({
            type: CLEAR_RESPONSE,
        })
    }

    const getStripeReports = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'reportDetails', formData, '', 'reports'),
            ])

            console.log(res)

            if (res.data.status === 'success') {
                dispatch({
                    type: SET_REPORT_DETAILS,
                    payload: res.data.data.responseData,
                })
                // resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('getStripeReport')
        }
    }

    return (
        <ReportContext.Provider
            value={{
                reportType: state.reportType,
                reportSummary: state.reportSummary,
                reportDetails: state.reportDetails,
                reports_data: state.reports_data,
                responseStatus: state.responseStatus,
                paymentSummay: state.paymentSummay,
                payoutReport: state.payoutReport,
                getReportTypes,
                getReportSummary,
                getPaymentsSummary,
                getAllReportDisplay,
                getAllExportDownload,
                getAllReportDownload,
                sendPayoutReportRequest,
                downloadStripeReport,
                resetReport,
                clearResponse,
                getStripeReports,
            }}
        >
            {props.children}
        </ReportContext.Provider>
    )
}

export default ReportState
