import {
    COMMON_VALUES,
    BID_HISTORY_VALUE,
    CLEAR_BID_HISTORY,
    US_STATE_VALUE,
    ALL_COUNTRY_VALUE,
    CLEAR_SEARCH,
    SEARCH_VALUE,
    GET_STATIC_PAGE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    SET_PROFILE_DATA,
    FILE_DETAILS,
    CLEAR_FILE_DETAILS,
} from './commonTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case COMMON_VALUES:
            return {
                ...state,
                configVariable: action.payload.configVariable,
            }
        case SEARCH_VALUE:
            return {
                ...state,
                searchValue: action.payload,
            }
        case BID_HISTORY_VALUE:
            return {
                ...state,
                bidHistoryValue: action.payload,
            }
        case CLEAR_BID_HISTORY:
            return {
                ...state,
                bidHistoryValue: 0,
            }
        case GET_STATIC_PAGE:
            return {
                ...state,
                static_page: action.payload,
            }
        case US_STATE_VALUE:
            return {
                ...state,
                USStates: action.payload,
            }
        case ALL_COUNTRY_VALUE:
            return {
                ...state,
                AllCountries: action.payload,
            }
        case CLEAR_SEARCH:
            return {
                ...state,
                searchValue: null,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case SET_PROFILE_DATA:
            return {
                ...state,
                profile: action.payload,
            }
        case FILE_DETAILS:
            return {
                ...state,
                fileDetails: action.payload,
            }
        case CLEAR_FILE_DETAILS: {
            return {
                ...state,
                fileDetails: {},
            }
        }
        default:
            return state
    }
}
