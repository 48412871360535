import React, { useReducer } from 'react'
import PayContext from './payContext'
import PayReducer from './payReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'
import { RESPONSE_STATUS, CLEAR_RESPONSE } from './payTypes'

const PayState = (props) => {
    const initialState = {
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(PayReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const payCart = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'authorize', formData, '', 'payment')])
            resp.commonResponse(res.data, 'payment')
        } catch (err) {
            resp.commonErrorResponse('payment')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <PayContext.Provider
            value={{
                responseStatus: state.responseStatus,
                payCart,
                clearResponse,
            }}
        >
            {props.children}
        </PayContext.Provider>
    )
}

export default PayState
