import React, { useState, useEffect } from 'react'
import './invoice.css'
import { mapData, currencyFormat } from '../../common/components'
import Accordion from './Accordion'

import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Switch from '@material-ui/core/Switch'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PublishIcon from '@material-ui/icons/Publish'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'

export default function Invoice(props) {
    const { formik } = props
    const [items, setItems] = useState([{ id: 0, item_name: 'item1', qty: 2, unit_price: 1000 }])

    const mapBuyerDetails = [
        {
            placeholder: 'Find a buyer...',
            className: 'm-0 pt-3 pr-3 pb-3',
            type: 'select',
            options: [{ show: 'Find a buyer...', value: '' }],
            name: 'buyer',
            formik: formik,
        },
    ]

    const mapVendorDetails = [
        {
            placeholder: 'Find a vendor...',
            className: 'm-0 pt-3 pr-3 pb-3',
            type: 'select',
            options: [{ show: 'Find a vendor...', value: '' }],
            name: 'vendor',
            formik: formik,
        },
    ]

    // function createData(id, item_name, qty, unit_price) {
    //     return { id, item_name, qty, unit_price }
    // }

    // let rows = [
    //     [0, 'Item 1', 2, 1000],
    //     [1, 'Item 2', 2, 1000],
    // ]

    // useEffect(() => {
    //     rows.map((value) => {
    //         let obj = createData(...value)
    //         setItems([...items, obj])
    //     })
    // }, [])

    const handleAddItem = () => {
        setItems([...items, { id: 0, item_name: 'item1', qty: 2, unit_price: 1000 }])
    }

    return (
        <>
            <Accordion
                id="buyer_vendor"
                title={
                    <div className="row w-100">
                        <div className="col-6">Buyers</div>
                        <div className="col-6">Vendors</div>
                    </div>
                }
            >
                <div className="row w-100">
                    <div className="col-6"> {Object.values(mapData(mapBuyerDetails))}</div>
                    <div className="col-6"> {Object.values(mapData(mapVendorDetails))}</div>
                </div>
                <div
                    style={{
                        width: '24px',
                        padding: '28px',
                        marginRight: '-12px',
                        marginLeft: '15px',
                    }}
                ></div>
            </Accordion>

            <Accordion id="items" title="Items">
                <div className="table">
                    <div className="icon">
                        <PublishIcon />
                    </div>
                    <div className="table_container">
                        <TableContainer component={Paper}>
                            <Table className="table_wrapper" aria-label="simple table">
                                <TableHead>
                                    <TableRow className="table_row">
                                        <TableCell>ITEM</TableCell>
                                        <TableCell>QTY</TableCell>
                                        <TableCell>UNIT PRICE</TableCell>
                                        <TableCell>AMOUNT</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell component="th" scope="row">
                                                {row.item_name}
                                            </TableCell>
                                            <TableCell>{row.qty}</TableCell>
                                            <TableCell>{currencyFormat(row.unit_price)}</TableCell>
                                            <TableCell>
                                                {currencyFormat(row.unit_price * row.qty)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <Button
                                                style={{ paddingLeft: '0', fontWeight: 'bold' }}
                                                onClick={handleAddItem}
                                            >
                                                +ADD NEW ITEM
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="item_footer">
                        <div className="btn_group">
                            <div className="btn_group1">
                                <Button>ADD SHIPPING COST</Button>
                                <Button>ADD DISCOUNT</Button>
                                <Button>ADD TAX</Button>
                            </div>
                            <div className="btn_details">
                                <TextField placeholder="$ Add shipping cost" variant="outlined" />
                                <Button className="btn_apply" variant="contained">
                                    Apply
                                </Button>
                                <CloseIcon />
                            </div>
                            <div className="btn_details">
                                <TextField placeholder="$ Add discount cost" variant="outlined" />
                                <Button className="btn_apply" variant="contained">
                                    Apply
                                </Button>
                                <CloseIcon />
                            </div>
                            <div className="btn_details">
                                <TextField placeholder="$ Add tax" variant="outlined" />
                                <Button className="btn_apply" variant="contained">
                                    Apply
                                </Button>
                                <CloseIcon />
                            </div>
                        </div>
                        <div className="total">Total: {currencyFormat(0)}</div>
                    </div>
                </div>
            </Accordion>

            <Accordion id="payment_terms" title="Payment Terms">
                <div className="date_picker">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="outlined"
                            name="date"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            value={formik.values.date}
                            onChange={(value) => formik.setFieldValue('date', value)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </Accordion>

            <Accordion id="advanced" title="Advanced Options">
                <div className="payout">
                    <div className="payout_subtitle">Auto payout</div>
                    <div className="payout_toggle">
                        Toggling this on means the vendor will receive their payout as soon as the
                        buyer pays.
                        <div className="payout_tooglebtn">
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value={formik.values.payout}
                                        control={
                                            <Switch
                                                color="primary"
                                                name="payout"
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                            />
                                        }
                                        onChange={formik.handleChange}
                                        label="Auto payout"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>

                    <div className="payout_subtitle mt-4">Payment methods available</div>
                    <div className="payout_toggle">
                        Select which payment menthods will be available to buyer.
                        <div className="payout_tooglebtn">
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formik.values.payment_menthod_bank}
                                                onChange={formik.handleChange}
                                                name="payment_menthod_bank"
                                                color="primary"
                                            />
                                        }
                                        label="Bank"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formik.values.payment_menthod_cheque}
                                                onChange={formik.handleChange}
                                                name="payment_menthod_cheque"
                                                color="primary"
                                            />
                                        }
                                        label="Cheque"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formik.values.payment_menthod_card}
                                                onChange={formik.handleChange}
                                                name="payment_menthod_card"
                                                color="primary"
                                            />
                                        }
                                        label="Card"
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </Accordion>

            <Accordion id="notes" title="Notes">
                <div className="notes">
                    <div className="notes_subtitle">Notes</div>
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={4}
                        variant="outlined"
                        placeholder="Write your notes to the buyer here"
                        fullWidth
                    />
                    <div className="notes_subtitle mt-4">Reference #</div>
                    <TextField
                        className="mb-4"
                        id="outlined-multiline-static"
                        variant="outlined"
                        placeholder="Write your notes to the buyer here"
                        fullWidth
                    />
                </div>
            </Accordion>
        </>
    )
}
