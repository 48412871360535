import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_EMPLOYEE,
    GET_SINGLE_EMPLOYEE,
} from './employeeTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case GET_ALL_EMPLOYEE:
            return {
                ...state,
                all_employees: action.payload,
            }
        case GET_SINGLE_EMPLOYEE:
            return {
                ...state,
                single_employee: action.payload,
            }
        default:
            return state
    }
}
